import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import AlertPopup from '../AlertPopup/AlertPopup';
import MirrorLoader from '../Loader/MirrorLoader';

import './UserLevelSetting.scss';

const UserLevelSetting = ({
  profileCompletion, updateProfileCompletion,
  settingName, onConfig, offConfig,
}) => {
  const [processing, setProcessing] = useState(false);
  const [response, setResponse] = useState({});
  const settingConfig = profileCompletion.settings[settingName] ? onConfig : offConfig;

  const handleConfigureSetting = e => {
    e.preventDefault();

    setProcessing(true);
    updateProfileCompletion({
      id: profileCompletion.id,
      settings: { [settingName]: !(profileCompletion.settings[settingName]) },
    }).then(res => {
      if (res.errors) {
        setResponse({ type: 'error', heading: 'Error!', description: 'Please try again later.' });
      } else {
        setResponse({ type: 'success', heading: 'Success!', description: 'Your changes have been saved!' });
      }

      setProcessing(false);
    });
  }

  return (
    <div className="User-Level-Setting">
      {processing && <MirrorLoader message="Saving your changes..." />}

      <AlertPopup
        close={() => setResponse({})}
        kind={response.type}
        title={response.heading}
        messages={[response.description]}
      />

      <ButtonBox
        className={`Setting-Btn ${settingConfig?.btnClass || ''}`}
        text={
          <span className={`Setting-Btn-Txt ${settingConfig?.btnTxtClass || ''}`}>
            {settingConfig.text}
          </span>
        }
        onClick={e => handleConfigureSetting(e)}
      />
    </div>
  )
};

UserLevelSetting.propTypes = {
  profileCompletion: PropTypes.object.isRequired,
  updateProfileCompletion: PropTypes.func.isRequired,
  settingName: PropTypes.string.isRequired,
  onConfig: PropTypes.shape({
    btnClass: PropTypes.string,
    btnTxtClass: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
  offConfig: PropTypes.shape({
    btnClass: PropTypes.string,
    btnTxtClass: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
};

export default UserLevelSetting;
