import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { times } from 'lodash';

import Loader from '../../../Composed/Loader/Loader';
import StackedChart from '../../../HighCharts/VerticalStackedChart/StackedChart';
import BarStackedChart from '../../../HighCharts/BarStackedChart/BarStackedChart';

import { clearComplianceStats, fetchUserPerformance } from '../../../../Actions/Stats/B2BAdmin/ComplianceActions';
import { hashToQueryString } from '../../../../Util/Helper/GenericUtil';
import { pledgeTemplates } from '../../../../Constants/PledgesConstants';

const mapStateToProps = (state) => ({
  userPerformance: state.stats.b2bAdmin.compliances.userPerformance || {},
  filters: state.filters || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearComplianceStats: (key) => dispatch(clearComplianceStats(key)),
  fetchUserPerformance: (queryString) => dispatch(fetchUserPerformance(queryString))
});

const UserPerformance = ({ userPerformance, filters, clearComplianceStats, fetchUserPerformance }) => {
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);

  useEffect(()=> {
    setLoading(true);
    setShowChart(false);
    clearComplianceStats('userPerformance');
    fetchUserPerformance(hashToQueryString(filters)).then(res => setLoading(false));
  }, [fetchUserPerformance, filters]);

  useEffect(() => {
    if (!loading)
      setShowChart(true)
  }, [loading]);

  const injectPlaceholderValues = (template, language, level, target) => (
    template.replaceAll('__language__', language)
            .replaceAll('__level__', level)
            .replaceAll('__target__', target)
            .replaceAll('__canopy_scale__', 'Canopy Scale')
  )

  const renderEmpty = () => (
    <div className="No-Data">
      The users has not made any progress yet!
    </div>
  )

  const knowledgeAcquisitionChart = () => {
    const knowledgeAcquisitionSeriesData = {};
    const numOfQuestions = Object.keys(userPerformance.knowledgeAcquisition || {}).length;
    const qNumArray = times(numOfQuestions).map(k => `Q${k + 1}`);

    Object.values(userPerformance.knowledgeAcquisition || {}).map((questionData, qIdx) => (
      (questionData.data || []).map(userData => {
        let wrongAnswer = Object.values(userData || {}).find(x => x.is_correct == false)
        knowledgeAcquisitionSeriesData[questionData.id] = knowledgeAcquisitionSeriesData[questionData.id] || { correct: { y: 0, question: questionData.text, questionNum: `Question #${qIdx + 1}` }, inCorrect: { y: 0 } }
        if (wrongAnswer) {
          knowledgeAcquisitionSeriesData[questionData.id]['inCorrect']['y'] += 1;
        } else {
          knowledgeAcquisitionSeriesData[questionData.id]['correct']['y'] += 1;
        }
      })
    ))

    const knowledgeAcquisitionChartData = {
      title: 'Knowledge Acquisition',
      xAxis: qNumArray,
      yAxisTitle: '',
      tooltip:  `<p class="heading">{{POINT_0_POINT_QUESTION_NUM}}</p>` +
                `<p class="sub-heading">{{POINT_0_POINT_QUESTION}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_0_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_0_PERCENTAGE}}% Answered Correctly -- {{POINT_0_y}} users</span>` +
                `</div>` +
                `<div class="in-progress">` +
                  `<span class="box" style="backgroundColor: {{POINT_1_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_1_PERCENTAGE}}% Answered Incorrectly -- {{POINT_1_y}} users</span>` +
                `</div>`,
      series: [
        { name: 'Answered Correctly',  color: '#80cbc4', data: Object.values(knowledgeAcquisitionSeriesData).map(a => a.correct)},
        { name: 'Answered Incorrectly',  color: '#183c5b4d', data: Object.values(knowledgeAcquisitionSeriesData).map(a => a.inCorrect)}
      ]
    }

    return (
      <div className="Knowledge-Acquisition-Chart-Container">
        <div
          className="Knowledge-Acquisition-Chart VerticalStackedChart"
          id="Knowledge-Acquisition-Stacked-Chart"
        >
          {
            showChart &&
            <StackedChart
              id="Knowledge-Acquisition-Stacked-Chart"
              data={knowledgeAcquisitionChartData}
            />
          }
        </div>
      </div>
    )
  }

  const gradingSystem = () => (
    <div className="Progress-Container">
      <div className="Progress-Block">
        <div className="Text-Holder">
          <div className="Text">
            Sensitivity to Language, Literacy, and Dialect
          </div>
          <div className="Progress-Label">
            High
          </div>
        </div>
      </div>
      <div className="Progress-Block">
        <div className="Text-Holder">
          <div className="Text">
            Handling Requests for Friends/Family to Interpret
          </div>
          <div className="Progress-Label Low">
            Low
          </div>
        </div>
      </div>
      <div className="Progress-Block">
        <div className="Text-Holder">
          <div className="Text">
            Arranging for Bilingual Staff or Medical Interpretation
          </div>
          <div className="Progress-Label">
            High
          </div>
        </div>
      </div>
      <div className="Progress-Block">
        <div className="Text-Holder">
          <div className="Text">
            Documenting the Provision of Language Services
          </div>
          <div className="Progress-Label Mid">
            Mid
          </div>
        </div>
      </div>
    </div>
  )

  const renderUserPledgesChart = (pool, position) => {
    const userPledgesSeriesData = { completed: [], inProgress: [], outstanding: [] };

    pool.map(([desc, descData]) => {
      userPledgesSeriesData.completed.push({
        y: Math.round(((descData.completed.count)  / (descData.total)) * 100),
        count: descData.completed.count,
        users: descData.completed.users.length,
        template: injectPlaceholderValues(pledgeTemplates[desc], 'Language', `${descData.levels[0] || ''}..${descData.levels.at(-1) || ''}`, `${descData.targets[0] || ''}..${descData.targets.at(-1) || ''}`)
      });

      userPledgesSeriesData.inProgress.push({
        y: Math.round(((descData.inProgress.count)  / (descData.total)) * 100),
        count: descData.inProgress.count,
        users: descData.inProgress.users.length,
        template: injectPlaceholderValues(pledgeTemplates[desc], 'Language', `${descData.levels[0] || ''}..${descData.levels.at(-1) || ''}`, `${descData.targets[0] || ''}..${descData.targets.at(-1) || ''}`)
      });

      userPledgesSeriesData.outstanding.push({
        y: Math.round(((descData.outstanding.count)  / (descData.total)) * 100),
        count: descData.outstanding.count,
        users: descData.outstanding.users.length,
        template: injectPlaceholderValues(pledgeTemplates[desc], 'Language', `${descData.levels[0] || ''}..${descData.levels.at(-1) || ''}`, `${descData.targets[0] || ''}..${descData.targets.at(-1) || ''}`)
      });
    });

    const userPledgesChartData = {
      title: `${(position == 'Left' && 'User Pledges in "My Action Plan"') || ''}`,
      xAxis: pool.map(([desc, descData]) => desc),
      yAxisTitle: '',
      tooltip:  `<p class="heading">{{POINT_0_X}}</p>` +
                `<p class="sub-heading">{{POINT_0_POINT_TEMPLATE}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_0_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_0_PERCENTAGE}}% Completed -- {{POINT_0_POINT_USERS}} users({{POINT_0_POINT_COUNT}} pledges)</span>` +
                `</div>` +
                `<div class="in-progress">` +
                  `<span class="box" style="backgroundColor: {{POINT_1_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_1_PERCENTAGE}}% In Progress -- {{POINT_1_POINT_USERS}} users({{POINT_1_POINT_COUNT}} pledges)</span>` +
                `</div>` +
                `<div class="in-progress">` +
                  `<span class="box" style="backgroundColor: {{POINT_2_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_2_PERCENTAGE}}% Outstanding -- {{POINT_2_POINT_USERS}} users({{POINT_2_POINT_COUNT}} pledges)</span>` +
                `</div>`,
      series: [
        { name: 'Completed',  color: '#80cbc4', data: userPledgesSeriesData.completed },
        { name: 'In Progress',  color: '#7986cb', data: userPledgesSeriesData.inProgress },
        { name: 'outstanding',  color: '#183c5b4d', data: userPledgesSeriesData.outstanding }
      ]
    }

    return (
      <div className={`User-Pledges-${position}-Chart-Container`}>
        <div
          className={`User-Pledges-${position}-Chart BarStackedChart`}
          id={`User-Pledges-${position}-Stacked-Chart`}
        >
          {
            showChart &&
            <BarStackedChart
              id={`User-Pledges-${position}-Stacked-Chart`}
              data={userPledgesChartData}
            />
          }
        </div>
      </div>
    )
  }

  const userPledgesChart = () => {
    let data = Object.entries(userPerformance.userPledges || {});
    const middleIndex = Math.ceil(data.length / 2);
    const firstHalf = data.splice(0, middleIndex);

    return (
      <div className="AllCharts">
        {renderUserPledgesChart(firstHalf, 'Left')}
        {renderUserPledgesChart(data, 'Right')}
      </div>
    )
  }

  const renderContent = () => {
    if (loading)
      return <Loader />

    if (Object.keys(userPerformance).length === 0)
      return renderEmpty();

    return (
      <>
        {knowledgeAcquisitionChart()}
        {gradingSystem()}
        {/*{userPledgesChart()}*/}
      </>
    )
  }

  return (
    <div className="Compliance-User-Performance">
      <div className="Title">User Performance</div>
      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPerformance);
