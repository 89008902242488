import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import './LearningPage.css';
import LessonAnswerButtons from '../../Composed/LessonAnswerButtons/LessonAnswerButtons';
import QuizSummaryBox from '../../Composed/QuizSummaryBox/QuizSummaryBox';
import TextContentBox from '../../Presentational/TextContentBox/TextContentBox';
import MultiButtonChoiceContainer from '../../Quiz/MultiButtonChoiceBox/MultiButtonChoiceContainer';
import MultiButtonAnswerContainer from '../../Quiz/MultiButtonAnswerBox/MultiButtonAnswerContainer';
import FillInTheBlankContainer from '../../Quiz/FillInTheBlankBox/FillInTheBlankContainer';
import TrueOrFalseContainer from '../../Quiz/TrueOrFalseBox/TrueOrFalseContainer';
import DropDownContainer from '../../Quiz/DropDownBox/DropDownContainer';
import DragAndDropBox from '../../Quiz/DragAndDropBox/DragAndDropBox';
import MatchingBox from '../../Quiz/MatchingBox/MatchingBox';
import DialogBox from '../../Learning/DialogBox/DialogBox';
import Flashcard from '../../Learning/Flashcard/Flashcard';
import SentenceOrderingBox from '../../Quiz/SentenceOrderingBox/SentenceOrderingBox';
import WordScrambleContainer from '../../Quiz/WordScrambleBox/WordScrambleContainer';
import SortingBox from '../../Quiz/SortingBox/SortingBox';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';
import AudioProgressBox from '../../Basic/AudioProgressBox/AudioProgressBox';
import SpanishKeyboard from '../../Composed/SpanishKeyboard/SpanishKeyboard';
import VideoBox from '../../Basic/VideoBox/VideoBox';
import YouTubeVideo from '../../Basic/YouTubeVideoBox/YouTubeVideo';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import InformationModal from '../../Composed/InformationModal/InformationModal';

import { instructionsContent, secondsToMiliseconds } from '../../../Util/Helper/GenericUtil';
import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { isStudent } from '../../../Util/Helper/UserWorkplaceUtil';
import { trackSeatTime, syncSeatTime } from '../../../Actions/SeatTimeActions';

class LearningPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentSectionIndex: 0,
            revealAnswers: false,
            checkAnswers: false,
            displayBookmarkSection: true,
            displayCommentButton: true,
            slide: true,
            summary: false,
            isSavingProgress: false,
            saveProgressRetryCount: 0,
        };
        this.seatTimeTracker = {};
    }

    componentDidMount() {
        let queryString = new URLSearchParams(this.props.location.search);
        document.addEventListener('visibilitychange', this.onVisibilityChange);
        window.addEventListener('beforeunload', this.onUnload);

        const {
            clearTopics,
            fetchTopic,
            topicId,
            navigationSectionIndex,
            receiveSectionIndex,
            clearQuizProgresses,
            clearQuizStates,
        } = this.props;

        syncSeatTime();
        clearTopics();
        clearQuizProgresses();
        clearQuizStates();
        fetchTopic(topicId).then(
            () => {
                this.fillOutSavedAnswers();
                if (navigationSectionIndex || navigationSectionIndex === 0) {
                    receiveSectionIndex(navigationSectionIndex);
                    this.setState({ currentSectionIndex: navigationSectionIndex });
                    this.handleSeatTimeStart(this.props.topic, navigationSectionIndex);
                } else if (queryString.has('nextSec')) {
                    receiveSectionIndex(parseInt(queryString.get('nextSec')));
                } else {
                    receiveSectionIndex(0);
                }
                this.setState({ loading: false }, () => {
                    this.handleNextSection('right', true)();
                });

                if (queryString.has('copn'))
                    document.getElementsByClassName('Comments-Button')[0]?.click();
            },
        );
    }

    componentDidUpdate(prevProps) {
        const {
            clearTopics,
            fetchTopic,
            topicId,
            navigationSectionIndex,
            receiveSectionIndex,
            clearQuizProgresses,
            clearQuizStates,
        } = this.props;

        if (prevProps.topicId !== topicId) {
            this.setState({ loading: true });
            this.handleSeatTimeEnd(prevProps.topic, prevProps.navigationSectionIndex);
            syncSeatTime();
            clearTopics();
            clearQuizProgresses();
            clearQuizStates();
            fetchTopic(topicId).then(
                () => {
                    this.fillOutSavedAnswers();
                    if (navigationSectionIndex || navigationSectionIndex === 0) {
                        receiveSectionIndex(navigationSectionIndex);
                        this.setState({ currentSectionIndex: navigationSectionIndex, slide: true, summary: false });
                        this.handleSeatTimeStart(this.props.topic, navigationSectionIndex);
                    } else {
                        receiveSectionIndex(0);
                        this.setState({ currentSectionIndex: 0, slide: true, summary: false });
                        this.handleSeatTimeStart(this.props.topic, 0);
                    }
                    this.setState({ loading: false }, () => {
                        this.handleNextSection('right', true)();
                    });
                },
            );
        } else if (prevProps.navigationSectionIndex !== navigationSectionIndex) {
            this.handleSeatTimeEnd(this.props.topic, prevProps.navigationSectionIndex);
            this.setState({ currentSectionIndex: navigationSectionIndex }, () => {
                if (!this.state.loading) {
                    this.handleNextSection('right', true)();
                }
            });
            this.handleSeatTimeStart(this.props.topic, navigationSectionIndex);
        }
    }

    componentWillUnmount() {
        this.handleSeatTimeEnd(this.props.topic, this.props.navigationSectionIndex)
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
        window.removeEventListener('beforeunload', this.onUnload);
        const { clearSectionIndex } = this.props;
        clearSectionIndex();
    }

    onUnload = () => {
        this.handleSeatTimeEnd(this.props.topic, this.props.navigationSectionIndex);
    }

    onVisibilityChange = () => {
        switch (document.visibilityState) {
            case 'hidden':  return this.handleSeatTimeEnd(this.props.topic, this.props.navigationSectionIndex);
            case 'visible': return this.handleSeatTimeStart(this.props.topic, this.props.navigationSectionIndex);
            default:        return null;
        }
    }

    handleSeatTimeStart = (ofTopic, ofSectionIndex) => {
        if (!isStudent(this.props.currentUser) || !ofTopic?.sections?.[ofSectionIndex] || this.seatTimeTracker.start) {
            return null;
        }

        clearInterval(this.seatTimeTracker.intervalId);

        this.seatTimeTracker = {
            start: Date.now(),
            lastActive: Date.now(),
            intervalId: setInterval(() => {
                if ((this.seatTimeTracker.lastActive + secondsToMiliseconds(15)) > Date.now()) {
                    this.seatTimeTracker['lastActive'] = Date.now();
                } else {
                    clearInterval(this.seatTimeTracker.intervalId);
                }
            }, secondsToMiliseconds(10)),
        };
    }

    handleSeatTimeEnd = (ofTopic, ofSectionIndex) => {
        if (!isStudent(this.props.currentUser) || !ofTopic?.sections?.[ofSectionIndex] || !this.seatTimeTracker.start) {
            return null;
        }

        clearInterval(this.seatTimeTracker.intervalId);

        let diffInLastActiveAndNow  = Date.now() - this.seatTimeTracker.lastActive;
        this.seatTimeTracker['end'] = (diffInLastActiveAndNow >= secondsToMiliseconds(0) && diffInLastActiveAndNow <= secondsToMiliseconds(10)) ? Date.now() : this.seatTimeTracker.lastActive;

        if ((this.seatTimeTracker.end - this.seatTimeTracker.start) < secondsToMiliseconds(5)) {
            this.seatTimeTracker = {};
            return null;
        }

        trackSeatTime(`seat-time-of-topic-${ofTopic.id}`, {
            seat_timeable_type: 'Section',
            seat_timeable_id: ofTopic.sections[ofSectionIndex].id,
            start: (new Date(this.seatTimeTracker.start)).toUTCString(),
            end: (new Date(this.seatTimeTracker.end)).toUTCString(),
        });

        this.seatTimeTracker = {};
    }

    fillOutSavedAnswers = () => {
        const { course, lesson, topic, courseProgresses, recordQuizAnswer } = this.props;
        let filledAnswers = {};

        (topic?.sections || []).map(section => (
            (section.questions || []).map(question => {
                if (section.section_type === 'multiple_answer') {
                    (question.options || []).map((option, idx) => {
                        let savedAnswer = (courseProgresses || {})[course.id]?.progress_data?.lessonProgresses?.[lesson.id]?.topicProgresses?.[topic.id]?.sectionProgresses?.[section.id]?.[question.id]?.[idx];
                        if (savedAnswer) {
                            filledAnswers = { ...filledAnswers, [lesson.id]: { ...(filledAnswers[lesson.id] || {}), [section.id]: { ...(filledAnswers[lesson.id]?.[section.id] || {}), [question.id]: { ...(filledAnswers[lesson.id]?.[section.id]?.[question.id] || {}) } } } };
                            filledAnswers[lesson.id][section.id][question.id][idx] = { ...savedAnswer, is_correct: question.answers?.map(a => a.text)?.includes(option.text) };
                        }
                    })
                } else if (section.section_type === 'sorting') {
                    (question.answers || []).map(answer => {
                        let savedAnswer = (courseProgresses || {})[course.id]?.progress_data?.lessonProgresses?.[lesson.id]?.topicProgresses?.[topic.id]?.sectionProgresses?.[section.id]?.[question.id]?.[answer.id];
                        if (savedAnswer) {
                            filledAnswers = { ...filledAnswers, [lesson.id]: { ...(filledAnswers[lesson.id] || {}), [section.id]: { ...(filledAnswers[lesson.id]?.[section.id] || {}), [question.id]: { ...(filledAnswers[lesson.id]?.[section.id]?.[question.id] || {}) } } } };
                            filledAnswers[lesson.id][section.id][question.id][answer.id] = { ...savedAnswer, is_correct: (savedAnswer.text === answer.text) };
                        }
                    })
                } else {
                    (question.answers || []).map((answer, idx) => {
                        let savedAnswer = (courseProgresses || {})[course.id]?.progress_data?.lessonProgresses?.[lesson.id]?.topicProgresses?.[topic.id]?.sectionProgresses?.[section.id]?.[question.id]?.[idx];
                        if (savedAnswer) {
                            filledAnswers = { ...filledAnswers, [lesson.id]: { ...(filledAnswers[lesson.id] || {}), [section.id]: { ...(filledAnswers[lesson.id]?.[section.id] || {}), [question.id]: { ...(filledAnswers[lesson.id]?.[section.id]?.[question.id] || {}) } } } };
                            filledAnswers[lesson.id][section.id][question.id][idx] = { ...savedAnswer, is_correct: (savedAnswer.text.trim().toLowerCase() === answer.text.trim().toLowerCase()) };
                        }
                    })
                }
            })
        ))

        recordQuizAnswer(filledAnswers);
    }

    handleNextSection = (direction, saveSilently = false) => () => {
        const {
            course,
            lesson,
            topic,
            history,
            courseId,
            lessonId,
            receiveSectionIndex,
            quizProgress,
            courseProgresses,
            saveCourseProgress,
        } = this.props;

        const {
            currentSectionIndex,
            saveProgressRetryCount,
        } = this.state;

        const currentSection = topic.sections[currentSectionIndex]
        if (!currentSection) {
            return null;
        }

        if (direction === 'right') {
            if (false && currentSection && currentSection.instruction_audio_url && !this.getSectionInstructionAudio(currentSection)) {
                document.getElementsByClassName('AudioProgressBox-Error')[0].innerText = 'Please listen to the full audio before moving on.'
                return null;
            }

            let saveProgressPromise;
            let sectionsSavedProgress = (courseProgresses || {})[course.id]?.progress_data?.lessonProgresses?.[lesson.id]?.topicProgresses?.[topic.id]?.sectionProgresses || {};
            let currentSectionSavedProgress = sectionsSavedProgress[currentSection.id] || {};
            let currentSectionNewProgress = quizProgress?.[currentSection.id] || {};
            let isCurrentSectionViewed = Object.keys(sectionsSavedProgress).includes(currentSection.id.toString());
            let isSectionProgressSame = isEqual(currentSectionSavedProgress, currentSectionNewProgress);
            if (isCurrentSectionViewed && isSectionProgressSame) {
                saveProgressPromise = Promise.resolve();
            } else {
                this.setState({ isSavingProgress: true });
                saveProgressPromise = saveCourseProgress({
                    course_id: course.id,
                    progress_data: {
                        lessonProgresses: {
                            [lesson.id]: {
                                completedTopics: [],
                                topicProgresses: {
                                    [topic.id]: {
                                        sectionProgresses: {
                                            [currentSection.id]: {},
                                        },
                                    },
                                },
                            },
                        },
                    },
                }, currentSectionNewProgress, lesson.id, topic.id, currentSection.id);
            }
            saveProgressPromise.then((res) => {
                this.setState({ isSavingProgress: false });
                if (saveSilently) {
                    // in this case, no action is required as it only marks the current slide as viewed silently.
                } else if (res?.errors) {
                    this.setState({ saveProgressRetryCount: saveProgressRetryCount + 1 });
                } else {
                    this.setState({ saveProgressRetryCount: 0 });
                    if (currentSectionIndex === (topic.sections.length - 1)) {
                        receiveSectionIndex(currentSectionIndex + 1);
                        this.setState({ slide: false, summary: true });
                    } else {
                        gaEvent('next_slide');
                        this.setState({ revealAnswers: false, checkAnswers: false }, () => {
                            receiveSectionIndex(currentSectionIndex + 1);
                        });
                    }
                }
            });
        } else if (direction === 'left') {
            if (currentSectionIndex === 0) {
                history.push(`/learn/courses/${courseId}/lessons`);
            } else {
                gaEvent('previous_slide');
                this.setState({ revealAnswers: false, checkAnswers: false }, () => {
                    receiveSectionIndex(currentSectionIndex - 1);
                });
            }
        }
    }

    hasAnswers = section => {
        let result = false;
        section.questions.forEach(question => {
            if (question.answers.length) {
                result = true;
            }
        });
        return result;
    }

    handleResetSection = section => () => {
        gaEvent('reset_slide', { section_id: section.id });
        const { resetQuizAnswer, lesson } = this.props;
        resetQuizAnswer({
            lessonId: lesson.id,
            sectionId: section.id,
        });
        this.setState({
            revealAnswers: false,
            checkAnswers: false,
        });
    }

    handleInstructions = () => {
        const { course, topic } = this.props;
        const { currentSectionIndex } = this.state;

        const currentSection = topic.sections[currentSectionIndex];

        let finalInstructions = '';

        if (course?.course_type?.name === 'Compliance') {
            finalInstructions = currentSection.instructions;
        } else {
            finalInstructions = currentSection.instructions ? (`<p>${currentSection.position}. ${currentSection.name}</p><br>${currentSection.instructions}`) : (`${currentSection.position}. ${currentSection.name}`);
        }

        return (
            finalInstructions && instructionsContent(finalInstructions) &&
            <div className="LearningPage-Instructions">
                <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(finalInstructions) }}
                />
            </div>
        )
    }

    isBookmarkedSection = section => {
        const { bookmarks } = this.props;

        if (!section) {
            return false;
        }

        return bookmarks?.ids?.sections?.includes(section.id);
    }

    bookmarkSection = section => () => {
        const {
            createBookmark,
            deleteBookmark
        } = this.props;

        const bookmark = { bookmarkable_type: 'Section', bookmarkable_id: section.id };
        if (this.isBookmarkedSection(section)) {
            gaEvent('unbookmark_slide', { section_id: section.id });
            deleteBookmark(bookmark)
        } else {
            gaEvent('bookmark_slide', { section_id: section.id });
            createBookmark(bookmark);
        }
    }

    setSectionInstructionAudio = section => () => {
        if (!section)
            return null;

        document.getElementsByClassName('AudioProgressBox-Error')[0].innerText = '';

        let listened = JSON.parse(window.localStorage.getItem('sectionInstructionAudios')) || [];

        if (listened.includes(section.id))
            return null;

        listened.push(section.id)
        window.localStorage.setItem('sectionInstructionAudios', JSON.stringify(listened));
    }

    getSectionInstructionAudio = section => {
        let listened = JSON.parse(window.localStorage.getItem('sectionInstructionAudios')) || [];
        return listened.includes(section.id);
    }

    handleRender() {
        const {
            course,
            topic,
            lesson,
            recordQuizAnswer,
            recordQuizState,
            quizProgress,
            courseProgresses,
            saveCourseProgress,
        } = this.props;

        const {
            currentSectionIndex,
            checkAnswers,
            revealAnswers,
            isSavingProgress,
            saveProgressRetryCount,
        } = this.state;

        const currentSection = topic.sections[currentSectionIndex];

        const newProps = {
            courseStudying: course,
            lessonId: lesson.id,
            section: currentSection,
            questions: currentSection.questions,
            recordQuizAnswer,
            recordQuizState,
            quizProgress,
            revealAnswers,
            checkAnswers,
            learning: true,
            isComplianceCourse: (course?.course_type?.name === 'Compliance')
        };

        let component;
        switch (currentSection.section_type) {
            case 'multiple_choice':
                component = <MultiButtonChoiceContainer section={currentSection} newProps={newProps} />
                break;
            case 'multiple_answer':
                component = <MultiButtonAnswerContainer section={currentSection} newProps={newProps} />
                break;
            case 'fill_in_the_blank':
                component = <FillInTheBlankContainer section={currentSection} newProps={newProps} />
                break;
            case 'true_or_false':
                component =  <TrueOrFalseContainer section={currentSection} newProps={newProps} />
                break;
            case 'drop_down':
                component =  <DropDownContainer section={currentSection} newProps={newProps} />
                break;
            case 'drag_and_drop':
                component = <DragAndDropBox {...newProps} />;
                break;
            case 'matching':
                component = <MatchingBox {...newProps} />;
                break;
            case 'sentence_ordering':
                component = <SentenceOrderingBox {...newProps} />;
                break;
            case 'dialog':
                component = <DialogBox { ...newProps } />;
                break;
            case 'word_scramble':
                component = <WordScrambleContainer section={currentSection} newProps={newProps} />
                break;
            case 'sorting':
                component = <SortingBox {...newProps} />;
                break;
            case 'flashcard':
                component = <Flashcard { ...newProps } />;
                break;
            default:
                component = null;
        }

        let saveProgressRetryAlert;
        switch (saveProgressRetryCount) {
            case 0:
                saveProgressRetryAlert = null;
                break;
            case 1:
                saveProgressRetryAlert = (
                    <InformationModal
                        title="Error"
                        descriptions={['Oops! There was a problem in saving your progress for this slide.']}
                        actionText="Try Again"
                        actionDisable={isSavingProgress}
                        actionCallBack={this.handleNextSection('right')}
                    />
                )
                break;
            case 2:
                saveProgressRetryAlert = (
                    <InformationModal
                        title="Error"
                        descriptions={['We are having trouble while saving your progress.', 'Here are some things you should check:']}
                        instructions={['Check if your internet is working.', 'Try refreshing the page.']}
                        actionCallBack={() => this.setState({ saveProgressRetryCount: 0 })}
                    />
                )
                break;
        }

        const nothingToRender = (currentSection.text_contents.length === 0) && !currentSection.audio_url && !currentSection.video_url && !currentSection.media_url && !component

        return (
            <div className={`LearningPage-Content ${nothingToRender ? 'Empty' : ''} ${currentSection.section_type}`}>
                {
                    (
                        currentSection.section_type === 'table_fill_in' ||
                        currentSection.section_type === 'fill_in_the_blank' ||
                        (currentSection.section_type === 'dialog' && this.hasAnswers(currentSection))
                    )
                    && course.language === 'SP'
                    && <SpanishKeyboard shouldFix={true} />
                }

                {
                    Boolean(currentSection.text_contents.length)
                    && (
                        <div className="LearningPage-TextBox">
                            <TextContentBox { ...newProps } />
                        </div>
                    )
                }

                {
                    currentSection.audio_url
                    && (
                        <div className="LearningPage-AudioPlayButton">
                            <AudioPlayButton
                                className="AudioPlayButton-LearningPage"
                                src={currentSection.audio_url}
                            />
                        </div>
                    )
                }

                {
                    currentSection.media_url ? (
                        <div className="LearningPage-VideoBox">
                            <YouTubeVideo shortUrl={currentSection.media_url} />
                        </div>
                    ) : (
                        currentSection.video_url &&
                        <div className="LearningPage-VideoBox">
                            <VideoBox src={currentSection.video_url} />
                        </div>
                    )
                }

                {component}
                {saveProgressRetryAlert}
            </div>
        );
    }

    render() {
        const { course, courseId, lessonId, topic } = this.props;
        const { loading, currentSectionIndex, checkAnswers, revealAnswers, slide, summary, isSavingProgress } = this.state;

        if (loading) {
            return <MirrorLoader message="Loading, Hang on!" />
        }

        if (!topic || !topic.sections.length || !topic.sections[0].text_contents || (currentSectionIndex === undefined)) {
            return <div className="LearningPage" />;
        }

        if (slide && !topic.sections[currentSectionIndex]) {
            return <div className="LearningPage" />
        }

        const currentSection = topic.sections[currentSectionIndex];

        return (
            <div className="LearningPage-Backdrop">
                <div className="LearningPage">
                    <div className="LearningPage-LessonAnswerButtons_container">
                        {
                            slide && (
                                <LessonAnswerButtons
                                    currentUser={this.props.currentUser}
                                    topic={topic}
                                    currentSection={currentSection}
                                    checkAnswers={checkAnswers}
                                    checkAnswerHandler={() => {
                                        gaEvent('check_slide_answer', { section_id: currentSection?.id });
                                        this.setState({ checkAnswers: !checkAnswers, });
                                    }}
                                    revealAnswers={revealAnswers}
                                    revealAnswerHandler={() => {
                                        gaEvent('reveal_slide_answer', { section_id: currentSection?.id });
                                        this.setState({ revealAnswers: !revealAnswers, })
                                    }}
                                    resetAnswerHandler={this.handleResetSection(currentSection)}
                                    displayBookmarkSection={this.state.displayBookmarkSection}
                                    isBookmarkedSection={this.isBookmarkedSection(currentSection)}
                                    bookmarkSection={this.bookmarkSection(currentSection)}
                                    displayLeftClick={true}
                                    leftClickHandler={this.handleNextSection('left')}
                                    displayRightClick={true}
                                    rightClickHandler={this.handleNextSection('right')}
                                    displayCommentButton={this.state.displayCommentButton}
                                    isSavingProgress={isSavingProgress}
                                />
                            )
                        }

                        { slide && this.handleInstructions() }

                        {
                            slide && currentSection?.instruction_audio_url &&
                            <AudioProgressBox
                                src={currentSection.instruction_audio_url}
                                onEndHandler={this.setSectionInstructionAudio(currentSection)}
                            />
                        }
                    </div>

                    {
                        summary
                        ? (
                            <QuizSummaryBox
                                courseId={courseId}
                                lessonId={lessonId}
                                topic={topic}
                            />
                        )
                        : (
                            this.handleRender()
                        )
                    }
                </div>
            </div>
        );
    }
}

LearningPage.propTypes = {
    clearTopics: PropTypes.func.isRequired,
    fetchTopic: PropTypes.func.isRequired,
    topicId: PropTypes.string.isRequired,
    navigationSectionIndex: PropTypes.number,
    receiveSectionIndex: PropTypes.func.isRequired,
    clearSectionIndex: PropTypes.func.isRequired,
    topic: PropTypes.shape({
        id: PropTypes.number,
        sections: PropTypes.arrayOf(PropTypes.object),
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    courseId: PropTypes.string.isRequired,
    lessonId: PropTypes.string.isRequired,
    resetQuizAnswer: PropTypes.func.isRequired,
    lesson: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    course: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    recordQuizAnswer: PropTypes.func.isRequired,
    recordQuizState: PropTypes.func.isRequired,
    quizProgress: PropTypes.objectOf(PropTypes.object),
    courseProgresses: PropTypes.objectOf(PropTypes.object).isRequired,
    clearQuizProgresses: PropTypes.func.isRequired,
    bookmarks: PropTypes.objectOf(Array).isRequired,
    saveCourseProgress: PropTypes.func.isRequired,
    createBookmark: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired
};

export default LearningPage;

/*
Code for old conponent
<NavButtonBox
  hintText={
    currentSection.instructions ?
    `${currentSection.position}. ` + currentSection.name + " - " + currentSection.instructions
    :
    `${currentSection.position}. ` + currentSection.name
  }
  hintSRC="doctor.png"
  progressPercent={
    Math.floor(((this.state.currentSectionIndex + 1) / topic.sections.length) * 100)
  }
  leftClickHandler={this.handleNextSection("left")}
  rightClickHandler={this.handleNextSection("right")} />

handleProgressPercent() {
    let numAnswered = 0;
    Object.values(this.props.topic).forEach((sectionAnswers) => {
      numAnswered += Object.keys(sectionAnswers).length
    });

    let totalQuestions = 0;
    this.props.section.sections.forEach((section) => {
      totalQuestions += section.questions.length
    });

    return Math.floor(numAnswered / totalQuestions * 100)
  }
*/
