import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';

import AlertPopup from '../../Composed/AlertPopup/AlertPopup';
import AutoComplete from '../../Composed/AutoComplete/AutoComplete';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import DashboardFormBoxNoEdit from '../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import RadioBox from '../../Basic/RadioBox/RadioBox';

import { isTEAdmin, isB2BAdmin } from '../../../Util/Helper/UserWorkplaceUtil';

import './LicenseManagement.scss';

const LicenseManagement = ({
  currentUser,
  institutions, clearInstitutions, fetchInstitutions,
  deactivateUsers, migrateUsers,
}) => {
  const [licenses, setLicenses] = useState([]);
  const [attrs, setAttrs] = useState({ institutionId: null, licenseId: null, file: null });
  const [processing, setProcessing] = useState({ isProcessing: true, message: 'Getting things ready...' });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({ show: false, message: '' });

  const fileRef = useRef();

  useEffect(() => {
    if (isB2BAdmin(currentUser)) {
      setAttrs({ ...attrs, institutionId: currentUser.institution_id, licenseId: null });
      setLicenses(Object.values(currentUser.perLicenseSeats));
      setProcessing({ isProcessing: false });
    } else if (isTEAdmin(currentUser)) {
      clearInstitutions();
      fetchInstitutions().then(() => setProcessing({ isProcessing: false }));
    }
  }, [clearInstitutions, fetchInstitutions]);

  const validateForm = () => {
    const formErrors = {};

    if (!attrs.institutionId) {
      formErrors['institution:'] = 'Please select an institution!';
    }

    if (!attrs.licenseId) {
      formErrors['License:'] = 'Please select a license!';
    }

    if (!attrs.file) {
      formErrors['Excel:'] = 'Please attach a file!';
    }

    return formErrors;
  };

  const handleResponse = (response) => {
    setProcessing({ isProcessing: false });

    if (response.error) {
      setErrors({ '': response.error });
    }

    if (response.message) {
      setAttrs({ ...attrs, licenseId: null, file: null });
      fileRef.current.value = '';
      setSuccess({ show: true, message: response.message });
    }
  };

  const handleUpload = (e, action) => {
    e.preventDefault();

    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setProcessing({ isProcessing: true, message: 'Processing...' });

      const formData = new FormData();

      formData.append('mass_license[institution_id]', attrs.institutionId ?? '');
      formData.append('mass_license[license_id]', attrs.licenseId ?? '');
      formData.append('mass_license[file]', attrs.file);

      if (action === 'deactivate') {
        deactivateUsers(formData).then(res => handleResponse(res, action));
      } else if (action === 'migrate') {
        migrateUsers(formData).then(res => handleResponse(res, action));
      }
    }
  };

  return (
    <div className="License-Management-Container">
      <DashboardFormBoxNoEdit title="Manage License">
        <ul className="Instructions list-disc">
          <li>Upload an Excel file that contains Emails of the users.</li>
          <li>The file should be a valid Excel file.</li>
          <li>The sample file can be downloaded by clicking <a href="https://prod-canopy-railsapi-media-bucket.s3.us-east-1.amazonaws.com/user-emails-sample.xlsx" className="Download-Sample" target="_blank" rel="noopener noreferrer">here</a>.</li>
        </ul>

        {processing.isProcessing && <MirrorLoader message={processing.message} />}

        {
          success.show
          && (
            <AlertPopup
              close={() => setSuccess({ show: false, message: '' })}
              kind="success"
              title={success.message}
            />
          )
        }

        {
          Object.values(errors).length > 0
          && (
            <div className="Error-Container mt-4">
              <ul className="Errors list-disc pl-10">
                {
                  Object.values(errors).map((error, idx) => (
                    <li key={`form-error-${idx}`} children={error} />
                  ))
                }
              </ul>
            </div>
          )
        }

        {
          isTEAdmin(currentUser)
          && (
            <div className="Institution-Field">
              <label className="Label">Institutions<span className="Required-Asterisk">*</span></label>
              <div className="Institutions">
                <AutoComplete
                  className="Select"
                  options={sortBy(Object.values(institutions), i => i?.name?.toLowerCase()).map(inst => { return { value: inst.id, label: inst.name } })}
                  defaultValue={{}}
                  onChange={e => {
                    setAttrs({ ...attrs, institutionId: e.value, licenseId: null });
                    setLicenses(institutions[e.value].licenses || []);
                  }}
                  isMulti={false}
                />
              </div>
            </div>
          )
        }

        <div className="License-Field">
          <label className="Label">License<span className="Required-Asterisk">*</span></label>
          <div className="Licenses">
            {
              licenses.reverse().map((license, idx) => (
                <RadioBox
                  key={`license-${idx}`}
                  className="License"
                  name="license-choice"
                  text={license.access_code}
                  value={license.id}
                  checked={(parseInt(attrs.licenseId) === license.id)}
                  onChange={e => setAttrs({ ...attrs, licenseId: e.currentTarget.value })}
                />
              ))
            }
          </div>
        </div>

        <div className="File-Field">
          <label className="Label">Excel<span className="Required-Asterisk">*</span></label>
          <input
            type="file"
            className="Input"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={fileRef}
            onChange={e => setAttrs({ ...attrs, file: e.currentTarget.files[0] })}
          />
        </div>

        <div className="Actions">
          <div className="Action">
            <div className="Text">Deactivate users mentioned in the file for the selected license.</div>
            <div className="Disclaimer">Users will be deactivated only if they exist in the selected license.</div>
            <ButtonBox
              className="Btn Primary-Btn"
              text={<span className="Primary-Btn-Text">Deactivate Users</span>}
              onClick={(e) => handleUpload(e, 'deactivate')}
            />
          </div>

          <div className="Action">
            <div className="Text">Migrate users mentioned in the file to the selected license.</div>
            <div className="Disclaimer">Users will be migrated to the selected license only if they exist in any other license of your institution.</div>
            <ButtonBox
              className="Btn Primary-Btn"
              text={<span className="Primary-Btn-Text">Migrate Users</span>}
              onClick={(e) => handleUpload(e, 'migrate')}
            />
          </div>
        </div>
      </DashboardFormBoxNoEdit>
    </div>
  )
};

LicenseManagement.propTypes = {
  currentUser: PropTypes.shape({
    institution_id: PropTypes.number,
    perLicenseSeats: PropTypes.shape({}),
  }).isRequired,
  institutions: PropTypes.shape({}).isRequired,
  deactivateUsers: PropTypes.func.isRequired,
  migrateUsers: PropTypes.func.isRequired,
  clearInstitutions: PropTypes.func.isRequired,
  fetchInstitutions: PropTypes.func.isRequired,
};

export default LicenseManagement;
