import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ImageBox from '../../../Basic/ImageBox/ImageBox';
import CircleProgressBar from '../../../Basic/CircleProgressBar/CircleProgressBar';
import StreakConfigContainer from '../Engage/StreakConfig/StreakConfigContainer';
import { statusToPercentage } from '../../../../Constants/CredentialConstants';
import TutorialContainer from '../../../Presentational/Tutorial/TutorialContainer';

import './Home.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  currentWorkplace: state.navigation.currentWorkplace,
  profileCompletion: state.userEntities.profileCompletion,
  flippers: state.access.flippers,
});

const mapDispatchToProps = (dispatch) => ({});

const {
  REACT_APP_ONE_DOMAIN
} = process.env;

const Home = ({ currentUser, currentWorkplace, profileCompletion, flippers, userDashboardReady }) => {
  const completedBasicInfo     = profileCompletion?.basic_info;
  const completedWorkplaceInfo = profileCompletion?.workplace_info;
  const completedLingoInfo     = profileCompletion?.lingo_info;
  const completedPledgeInfo    = profileCompletion?.pledge_info;
  const completedProfile       = (completedBasicInfo && completedWorkplaceInfo && completedLingoInfo && completedPledgeInfo);

  const workplaceCourses  = Object.values(currentWorkplace.courses || {});
  const complianceCourse  = workplaceCourses.filter(c => c.course_type === 'Compliance')[0];
  const learnCourse       = workplaceCourses.filter(c => c.course_type === 'Learn' && !c.course_completed)[0] || workplaceCourses.filter(c => c.course_type === 'Learn').at(-1);

  const profileCompletePercentage = () => {
    let arr = [completedBasicInfo, completedWorkplaceInfo, completedLingoInfo, completedPledgeInfo];

    return Math.floor((arr.filter(x => x).length / arr.length) * 100);
  }

  return (
    <div className="HomePage">
      {
        flippers?.actor?.streaks?.enabled &&
        profileCompletion.completed_at &&
        !profileCompletion.settings?.streak_config_blocked &&
        !currentUser.streakConfig &&
        <StreakConfigContainer />
      }

      { userDashboardReady && <TutorialContainer /> }

      <div className="Banner-Container Tour-Step-Home-Welcome-Banner">
        <div className="Texts">
          <h1 className="Heading">
            {`Welcome to your dashboard, ${currentUser.first_name} ${currentUser.middle_name} ${currentUser.last_name}!`}
          </h1>
        </div>
        <ImageBox className="BannerImage" src="medicine-banner.png" />
      </div>

      {
        !completedProfile &&
        <div className="Profile-Completeness-Container">
          <div className="Text-Holder">
            <h2 className="Text">Complete your profile</h2>
            <h4 className="Progress">{`${profileCompletePercentage()}% complete`}</h4>
          </div>

          <div className="Progress-Bar">
            <div className="Filler" style={{ width: `${profileCompletePercentage()}%`}} />
          </div>

          <div className="Ticks-Container">
            <div className="Tick-Container">
              <div className={completedBasicInfo ? 'Fill' : 'Empty'}>
                {completedBasicInfo && <i className="fa fa-check" />}
              </div>
              <p className="Text">
                <Link to="/account/profile">
                  Basic Info
                </Link>
              </p>
            </div>
            <div className="Tick-Container">
              <div className={completedWorkplaceInfo ? 'Fill' : 'Empty'}>
                {completedWorkplaceInfo && <i className="fa fa-check" />}
              </div>
              <p className="Text">
                <Link to="/account/profile">
                  Workplace info
                </Link>
              </p>
            </div>
            <div className="Tick-Container">
              <div className={completedLingoInfo ? 'Fill' : 'Empty'}>
                {completedLingoInfo && <i className="fa fa-check" />}
              </div>
              <p className="Text">
                <Link to="/account/profile?tab=my-languages">
                  Language background
                </Link>
              </p>
            </div>
            <div className="Tick-Container">
              <div className={completedPledgeInfo ? 'Fill' : 'Empty'}>
                {completedPledgeInfo && <i className="fa fa-check" />}
              </div>
              <p className="Text">
                <Link to="/account/profile?tab=my-progress">
                  Fill out your Pledges
                </Link>
              </p>
            </div>
          </div>
        </div>
      }

      <div className="Links-Container">
        <Link className="Link" to="/account/profile/?tab=my-progress&sec=subscriptions">
          <div className="Badge">
            <ImageBox className="Icon" src="subs.png" />
            <p className="Text">manage subscription</p>
          </div>
        </Link>
        <Link className="Link" to="/account/certificate/">
          <div className="Badge">
            <ImageBox className="Icon" src="shield.png" />
            <p className="Text">View certificates</p>
          </div>
        </Link>
        <a className="Link" target="_blank" href="https://share.hsforms.com/1559vlmxjRfCWYGzpkgwP_A3jlta" rel="noopener noreferrer">
          <div className="Badge">
            <ImageBox className="Icon" src="Group.png" />
            <p className="Text">Get support</p>
          </div>
        </a>
        <Link className="Link" to="/account/profile/?tab=my-progress">
          <div className="Badge">
            <ImageBox className="Icon" src="Vector.png" />
            <p className="Text">Update pledges</p>
          </div>
        </Link>
      </div>

      <div className="Cards-Holder">
        {
          currentUser.accessibleProducts.compliance[currentWorkplace.id]?.access &&
          <div className="Card">
            <div className="Card-Header">
              <ImageBox className="Icon" src="canopy-icon.png" />
              <p className="Text">Compliance</p>
            </div>
            <div className="Card-Content">
              <p className="Statement">Course on the regulatory requirements governing language access</p>
              <div className="Link-And-Progress-Holder">
                <Link to="/learn">
                  <div className="Button">
                    <span>{(complianceCourse?.completed_lessons || 0) === 0 ? 'BEGIN COURSE' : 'CONTINUE COURSE' }</span>
                  </div>
                </Link>
                <div className="Progress-Circle">
                  {
                    (complianceCourse?.completed_lessons || 0) !== 0 && (
                      <CircleProgressBar
                        percent={(complianceCourse.completed_lessons || 0) / (complianceCourse.non_test_lessons || 0) * 100}
                        heading="Compliance"
                        subHeading=""
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        }

        <div className="Card">
          <div className="Card-Header">
            <ImageBox className="Icon" src="canopy-icon.png" />
            <p className="Text">Credential</p>
          </div>
          <div className="Card-Content">
            <p className="Statement">
              Standardized exam for Qualified Bilingual Staff (ACA Section 1557). Click <a href="https://info.withcanopy.com/en/knowledge/canopycredential" target="_blank" rel="noopener noreferrer" className="underline">here</a> to learn more or take the practice test.
            </p>
            <div className="Link-And-Progress-Holder">
              <a href={currentUser.hasAccessToCredential ? `${REACT_APP_ONE_DOMAIN}` : `${REACT_APP_ONE_DOMAIN}/products`} target="_blank" rel="noopener noreferrer" className="Link">
                <div className="Button Tour-Step-Home-Credential-Button">
                  <span>{currentUser.hasAccessToCredential ? (currentUser.recentSapioTest ? 'CONTINUE TEST' : 'Get Enrolled') : 'Purchase'}</span>
                </div>
              </a>
              <div className="Progress-Circle">
                {
                  currentUser.recentSapioTest &&
                  <CircleProgressBar
                    percent={statusToPercentage[currentUser.recentSapioTest.status]}
                    heading={currentUser.recentSapioTest.status}
                    subHeading=""
                  />
                }
              </div>
            </div>
          </div>
        </div>

        <div className="Card">
          <div className="Card-Header">
            <ImageBox className="Icon" src="canopy-icon.png" />
            <p className="Text">Learn</p>
          </div>
          <div className="Card-Content">
            <p className="Statement">Comprehensive curriculum for fluency in Medical Spanish and Medical English</p>
            <div className="Link-And-Progress-Holder">
              <Link to="/learn">
                <div className="Button Tour-Step-Home-Learn-Button">
                  <span>{(learnCourse?.completed_lessons || 0) === 0 ? 'BEGIN COURSE' : 'CONTINUE COURSE' }</span>
                </div>
              </Link>
              <div className="Progress-Circle">
                {
                  (learnCourse?.completed_lessons || 0) !== 0 && (
                    <CircleProgressBar
                      percent={(learnCourse.completed_lessons || 0) / (learnCourse.non_test_lessons || 0) * 100}
                      heading={learnCourse.language === 'SP' ? 'Spanish' : 'English'}
                      subHeading={`Level ${learnCourse.level}`}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div className="Card">
          <div className="Card-Header">
            <ImageBox className="Icon" src="canopy-icon.png" />
            <p className="Text">Speak</p>
          </div>
          <div className="Card-Content">
            <p className="Statement">CanopySpeak is a multilingual Clinical Communication Support Tool that features a Learning Mode for mastering frequently used phrases and a Clinical Mode to support routine communication.</p>
            <div className="Info">
              <p className="Text"></p>
            </div>
            <div className="Link-And-Progress-Holder">
              <a href="https://info.withcanopy.com/canopy-speak" target="_blank" rel="noopener noreferrer">
                <div className="Button">
                  <span>LEARN MORE</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
