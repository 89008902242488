import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';

import ImageBox from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InstitutionSwitch from '../../NavBars/InstitutionSwitch/InstitutionSwitch';

import './B2BAdminSideBar.scss';

const mapStateToProps = (state) => ({
  flippers: state.access.flippers
});

const mapDispatchToProps = (dispatch) => ({});

const B2BAdminSideBar = ({ match, flippers }) => {
  const path = match.params.path;
  const fullPath = window.location.hash;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)'});

  const handleCollapseToggle = () => {
    const SideBar = document.querySelectorAll('.B2B-Admin-Side-Bar');
    SideBar.forEach((sideBar) => {
      sideBar.classList.toggle('B2B-Admin-Side-Bar-Show');
    });
  }

  const collapseAfterClick = () => {
    const SideBar = document.querySelectorAll('.B2B-Admin-Side-Bar');
    SideBar.forEach((sideBar) => {
      sideBar.classList.remove('B2B-Admin-Side-Bar-Show');
    });
  }

  const showSpeak = () => flippers?.actor?.speak_dashboard.enabled;
  const showAdminHomeDashboard = () => flippers?.actor?.admin_home_dashboard?.enabled;

  return (
    <div className="B2B-Admin-Side-Bar">
      <div className="B2B-Admin-Side-Bar-Switch">
        <InstitutionSwitch />
      </div>
      <div className="B2B-Admin-Side-Bar-List">
        {
          showAdminHomeDashboard() && (
            <div className="Home-Overlay">
              <Link
                to="/account/dashboard/home"
                onClick={() => collapseAfterClick()}
              >
                <div
                  className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/dashboard/home' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
                  <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png"/>
                  <span>Home</span>
                </div>
              </Link>
            </div>
          )
        }
        <div className="Professional-Overlay">
          <div className="Verticaltext">
            <p>Professional Development</p>
          </div>
          <div className="Dashboards Tour-Step-Sidebar-Dashboards-Links">
            <Link
              to="/account/dashboard/compliance"
              onClick = {() => collapseAfterClick()}
            >
              <div className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/dashboard/compliance' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
                <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png" />
                <span>Compliance</span>
              </div>
            </Link>
            <Link
              to="/account/dashboard/learn"
              onClick = {() => collapseAfterClick()}
            >
              <div className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/dashboard/learn' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
                <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png" />
                <span>Learn</span>
              </div>
            </Link>
            <Link
              to="/account/dashboard/credential"
              onClick = {() => collapseAfterClick()}
            >
              <div className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/dashboard/credential' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
                <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png" />
                <span>Credential</span>
              </div>
            </Link>
          </div>
          <Link
            to="/account/invite_users/detail"
            onClick = {() => collapseAfterClick()}
          >
            <div className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/invite_users/detail' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
              <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png" />
              <span>Invitations</span>
            </div>
          </Link>
          <Link
            to="/account/batch_profile_uploads/new"
            onClick={() => collapseAfterClick()}
          >
            <div className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/batch_profile_uploads/new' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
              <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png" />
              <span>Learner Profiles</span>
            </div>
          </Link>
          <Link
            to="/account/user_roster_uploads/new"
            onClick={() => collapseAfterClick()}
          >
            <div className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/user_roster_uploads/new' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
              <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png" />
              <span>Upload Roster</span>
            </div>
          </Link>
          <Link
            to="/account/license_management"
            onClick={() => collapseAfterClick()}
          >
            <div className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/license_management' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
              <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png" />
              <span>Manage License</span>
            </div>
          </Link>
          {
            showSpeak() && (
              <Link
                to="/account/dashboard/speak"
                onClick={() => collapseAfterClick()}
              >
                <div
                  className={`B2B-Admin-Side-Bar-Item ${fullPath === '#/account/dashboard/speak' ? 'B2B-Admin-Side-Bar-Item-Selected' : ''}`}>
                  <ImageBox className="B2B-Admin-Side-Bar-Image" src="canopy-icon.png"/>
                  <span>Speak</span>
                </div>
              </Link>
            )
          }
        </div>

        <Link
          to="/"
          onClick={() => collapseAfterClick()}
        >
          <ButtonBox
            className="Sidebar-ButtonBox"
            text="User / Account Management"
          />
        </Link>

        <Link
          to="/account/resource_library"
          onClick={() => collapseAfterClick()}
        >
          <ButtonBox
            className="Sidebar-ButtonBox Resource-Button"
            text="MANAGE RESOURCES"
          />
        </Link>
      </div>

      {
        isMobile &&
        <div className="SideBar-Collapse-Button">
          <ButtonBox
            className="ButtonBox-SideBar"
            text={<i className="fas fa-bars"></i>}
            onClick={() => handleCollapseToggle()}
          />
        </div>
      }
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(B2BAdminSideBar);
