import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';

import { gaEvent } from '../../../../Util/Helper/GoogleUtil';
import { languagesList } from '../../../../Constants/LanguageConstants';

const AddLanguage = ({ allLingoPledges, enablePledge, close }) => {
  const [selectedLingo, setSelectedLingo] = useState('');
  const [lingoErrors, setLingoErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const validateForm = () => {
    let errors = {};

    if (selectedLingo.length === 0) {
      errors['noLanguage'] = 'Please select a language to set goals for!';
    }

    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm();
    setLingoErrors(errors);

    if (Object.keys(errors).length === 0) {
      setSubmitting(true);

      enablePledge({ id: selectedLingo }).then(() => {
        setSubmitting(false);
        gaEvent('language_pledges_enabled', { language_name: Object.values(allLingoPledges).find(lp => lp.id.toString() === selectedLingo)?.language });
        close();
      });
    }
  }

  return (
    <div className="Enable-Lingo-Container">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h6>Select The Language you wish to set goals for</h6>
            <div className="btn-close" >
              <ButtonBox
                text={<ImageBox src="close.svg" />}
                disabled={submitting}
                onClick={() => close()}
              />
            </div>
          </div>
          <div className="modal-body">
            {
              (Object.keys(lingoErrors).length > 0)
              && (
                <div className="Error-Container">
                  <ul className="list-disc">
                    {
                      Object.values(lingoErrors).map((error, idx) => (
                        <li key={`error-${idx}`} children={error} />
                      ))
                    }
                  </ul>
                </div>
              )
            }

            {
              Object.values(allLingoPledges).find(l => !l.pledge_enabled) ? (
                <>
                  <label className="select" for="slct">
                    <select
                      id="slct"
                      className="ttc"
                      onChange={(e) => setSelectedLingo(e.currentTarget.value)}
                    >
                      <option value="">Select</option>
                      {
                        Object.values(allLingoPledges).map((lingoPledge, idx) => (
                          !lingoPledge.pledge_enabled &&
                          <option key={idx} value={lingoPledge.id}>
                            {languagesList[lingoPledge.language]}
                          </option>
                        ))
                      }
                    </select>
                  </label>
                  <ButtonBox
                    className="Btn AddLanguageButton"
                    text={<>Add Language</>}
                    disabled={submitting}
                    onClick={(e) => handleSubmit(e)}
                  />
                </>
              ) : (
                <div className="All-Lingos-Enabled-Text">
                  <p>Pledges are enabled for all added languages. Add more languages to enable pledges for.</p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

AddLanguage.propTypes = {
  allLingoPledges: PropTypes.object.isRequired,
  enablePledge: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

export default AddLanguage;
