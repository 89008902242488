import React, { useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import Filters from '../Filters/Filters';
import UserParticipation from './UserParticipation';
import UserPerformance from './UserPerformance';
import CredentialRosterManagement from '../UserRosterManagement/CredentialRosterManagement';
import Loader from '../../../Composed/Loader/Loader';

import { gaEvent } from '../../../../Util/Helper/GoogleUtil';

import './Credential.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

const Credential = ({ currentUser }) => {
  const [loading, setLoading] = useState(true);
  const [formIndex, setFormIndex] = useState(0);
  const formNames = ['OVERVIEW', 'USER ROSTER MANAGEMENT'];
  const [contentWidth, setContentWidth] = useState(0);

  useLayoutEffect (() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      if (window.outerWidth > 768) {
        pageWidth -= 450;
      } else if (window.outerWidth > 450 && window.outerWidth < 768) {
        pageWidth -= 150;
      } else {
        pageWidth -= 100;
      }

      setContentWidth(pageWidth);
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const renderDashboard = () => {
    switch(formIndex) {
      case 0:
        return (
          <div className="OverView-Container">
            <Filters
              showFilters={['timesFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {
              loading ? <Loader /> : (
                <>
                  <UserParticipation />
                  <UserPerformance contentWidth={contentWidth}/>
                </>
              )
            }
          </div>
        );
      case 1:
        return (
          <div className="User-Roster-Management-Container">
            <Filters
              showFilters={['examStatusesFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {loading ? <Loader /> : <CredentialRosterManagement />}
          </div>
        );
    }
  }

  if (!currentUser.accessibleProducts.credential.access) {
    gaEvent('credential-overview-purchase-presented', { user_id: currentUser.id, institution_id: currentUser.institution_id })

    return (
      <div className="B2B-Admin-Credential-Dashboard-Container">
        <div className="Product-Overview-Purchase">
          <i className="fa fa-key fa-5x Icon" />
          <div className="Heading">Canopy Credential</div>
          <div className="Description">
            CanopyCredential is a Qualified Bilingual Staff test available for purchase at an additional fee.
            Click&nbsp;
            <a
              href="https://info.withcanopy.com/en/knowledge/canopycredential#about-the-assessment"
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-blue-600 hover:text-blue-800"
              onClick={() => gaEvent('credential-overview-purchase-learn-more', { user_id: currentUser.id, institution_id: currentUser.institution_id })}
            >
              here
            </a>
            &nbsp;to learn more.
            If you would like to purchase Credential for your institution, all users' progress will be loaded into your Administrator Dashboard for review and approval.
            Contact your Canopy rep or&nbsp;
            <a
              href="mailto:info@canopyapps.com"
              class="underline text-blue-600 hover:text-blue-800"
              onClick={() => gaEvent('credential-overview-purchase-contact', { user_id: currentUser.id, institution_id: currentUser.institution_id })}
            >
              info@canopyapps.com
            </a>
            &nbsp;to learn more or get pricing.
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="B2B-Admin-Credential-Dashboard-Container">
      <div className="Cerdential-Dashboard-Tabs">
        {
          formNames.map((formName, idx) => (
            <div
              key={`compliance-dashboard-tab-${idx}`}
              className={`Tab-Button ${formIndex === idx ? 'Active' : '' }`}
              onClick={() => {formIndex === idx ? null : setLoading(true); setFormIndex(idx)}}
            >
              {formName}
            </div>
          ))
        }
      </div>

      {renderDashboard()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Credential);
