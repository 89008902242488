import React, { Component } from 'react';
import { sortBy } from 'lodash';

import InstitutionBox from '../../../Presentational/InstitutionBox/InstitutionBox';
import Loader from '../../../Composed/Loader/Loader';

class AdminDashboardInstitutions extends Component {
    state = { loading: true };

    componentDidMount() {
        const { fetchInstitutions } = this.props;
        fetchInstitutions().then(() => this.setState({ loading: false }));
    }

    handleInstitutionRender() {
        const { institutions } = this.props;

        const institutionList = sortBy(Object.values(institutions), i => i?.name?.toLowerCase()).map(
            institution => <InstitutionBox key={institution.id} institution={institution} />,
        );

        return institutionList;
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        }

        return (
            <div className="AdminDashboard-Pages">
                <div className="Institution-List">
                    { this.handleInstitutionRender() }
                </div>
            </div>
        );
    }
}

export default AdminDashboardInstitutions;
