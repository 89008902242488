import React from 'react';
import { Link } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import './Certificate.css';

const Card = ({ title, children }) => (
    <div className="Card-Container">
        <h1>
            {title}
        </h1>
        <hr style={{
            border: '0.5px solid #e7ebee',
        }}
        />
        <div className="Card-Desc">
            {children}
        </div>
    </div>
);

const UserDashboardCertificate = ({ currentUser, secureRoutes, courses, courseProgresses }) => {
    return (
        <div className="Certificates">
            <h1>Certificates</h1>
            <p className="Sub-Heading">In order to get your certificates, you must complete all lessons within a course, and pass all quizzes with a score greater than 70% in CanopyLearn and greater than 80% in CanopyCompliance.</p>
            {
                Object.values(courses || {}).map((course, idx) => {
                    const canDownloadCertificate = courseProgresses[course.id]?.completed_at && courseProgresses[course.id]?.result === 'pass';
                    return (
                        <div className="Card-Holder">
                            <Card title={course.name.toUpperCase()}>
                                <div className="Card-Inner">
                                    <p>CERTIFICATE OF COMPLETION</p>
                                    {
                                        canDownloadCertificate
                                            ? (
                                                <div className="Actions">
                                                    <Link to={`/account/certificate/${sha256(currentUser?.id)?.words[0]}/download/${secureRoutes?.[idx]?.route}`}>
                                                        <ButtonBox
                                                            className="Static-Primary-Btn Certificate-Button"
                                                            text="VIEW CERTIFICATE PDF"
                                                        />
                                                    </Link>

                                                    {
                                                        course.cmeo_certificate_link
                                                        && (
                                                            <a href={course.cmeo_certificate_link} className="Link" target="_blank">
                                                                <ButtonBox
                                                                    className="Static-Primary-Btn CMEO-Button"
                                                                    text="CLAIM CE CREDITS"
                                                                />
                                                            </a>
                                                        )
                                                    }
                                                </div>
                                            )
                                            : (
                                                <div className="Status">
                                                    NOT YET AVAILABLE
                                                </div>
                                            )
                                    }
                                </div>
                            </Card>
                        </div>
                    );
                })}
        </div>
    );
};

export default UserDashboardCertificate;
