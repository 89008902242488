import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { sortBy } from 'lodash';

import MirrorLoader from '../../../../Composed/Loader/MirrorLoader';
import ProgressLoader from '../../../../Composed/Loader/ProgressLoader';
import ButtonBox from '../../../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../../../Basic/InputBox/InputBox';
import AutoComplete from '../../../../Composed/AutoComplete/AutoComplete';

import './Institutions.scss';

const { REACT_APP_AUDIENCE } = process.env;

const Institutions = ({
  institutions, fetchInstitutions,
  courses, fetchCourses,
  total_licenses, processed_licenses,
  clearTEAdminInstitutionsStats, fetchTEAdminInstitutionsStats,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [flash, setFlash] = useState({ message: '', type: '' });
  const [institutionsFilter, setInstitutionsFilter] = useState({});
  const [normalFilters, setNormalFilters] = useState({ start_date: '', end_date: '', course_name: '', course_id: '' });
  const [comparisonFilters, setComparisonFilters] = useState({ start_date: '', end_date: '', course_name: '', course_id: '' });

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    Promise.all([
      clearTEAdminInstitutionsStats(),
      fetchInstitutions(),
      fetchCourses(),
    ]).then(() => setLoading(false));
  }, [clearTEAdminInstitutionsStats, fetchInstitutions, fetchCourses]);

  const handleGenerateReport = async (e) => {
    e.preventDefault();

    if (isAuthenticated) {
      setFlash({ message: '', type: '' });
      setProcessing(true);

      clearTEAdminInstitutionsStats();
      fetchTEAdminInstitutionsStats(institutionsFilter, normalFilters, comparisonFilters).then(() => {
        setProcessing(false);
        setFlash({ message: 'Report has been generated and downloaded successfully!', type: 'Info' });
      }).catch((err) => {
        setProcessing(false);
        setFlash({ message: 'Oops! something went wrong.', type: 'Danger' });
      });
    }
  }

  const handleInstitutionChange = (e) => {
    let selectedInstitutions = {};

    e.map(opt => selectedInstitutions[opt.value] = opt.label);

    setInstitutionsFilter(selectedInstitutions);
  }

  if (loading) {
    return (
      <MirrorLoader message="Loading ..." />
    )
  }

  return (
    <div className="Stats-Institutions">
      {
        processing &&
        <ProgressLoader
          message="Generating Report"
          completed={processed_licenses}
          total={total_licenses}
        />
      }

      {
        flash.message.length > 0 &&
        <div className={`Flash-Message ${flash.type}`}>{flash.message}</div>
      }

      <div className="Title">
        <h2>Organization Report</h2>
      </div>

      <div className="Top-Filters">
        <div className="Input-Container">
          <label>Institutions</label>
          <AutoComplete
            className="Institution-Select-Autocomplete"
            options={sortBy(Object.values(institutions), i => i?.name?.toLowerCase()).map((institution) => { return { value: institution.id, label: institution.name } })}
            defaultValue={Object.entries(institutionsFilter).map(([value, label]) => { return { value, label } })}
            onChange={(e) => handleInstitutionChange(e)}
            isMulti={true}
          />
        </div>
      </div>

      <div className="Bottom-Filters">
        <div className="Left-Filter">
          <div className="Input-Container">
            <label className="Label">Start Date:</label>
            <InputBox
              className="Input"
              type="date"
              value={normalFilters.start_date}
              onChange={(e) => setNormalFilters({ ...normalFilters, start_date: e.currentTarget.value })}
            />
          </div>

          <div className="Input-Container">
            <label className="Label">End Date:</label>
            <InputBox
              className="Input"
              type="date"
              value={normalFilters.end_date}
              onChange={(e) => setNormalFilters({ ...normalFilters, end_date: e.currentTarget.value })}
            />
          </div>

          <div className="Input-Container">
            <label className="Label">Course:</label>
            <select
              className="Input"
              defaultValue={normalFilters.course_id}
              onChange={(e) => setNormalFilters({ ...normalFilters, course_name: courses[e.currentTarget.value]?.name, course_id: e.currentTarget.value })}
            >
              <option key="course-0" value="" children="All" />
              {
                Object.values(courses).map((course, idx) => (
                  <option key={`course-${course.id}`} value={course.id} children={course.name} />
                ))
              }
            </select>
          </div>
        </div>

        <div className="Right-Filter">
          <div className="Input-Container">
            <label className="Label">Comparison Start Date:</label>
            <InputBox
              className="Input"
              type="date"
              value={comparisonFilters.start_date}
              onChange={(e) => setComparisonFilters({ ...comparisonFilters, start_date: e.currentTarget.value })}
            />
          </div>

          <div className="Input-Container">
            <label className="Label">Comparison End Date:</label>
            <InputBox
              className="Input"
              type="date"
              value={comparisonFilters.end_date}
              onChange={(e) => setComparisonFilters({ ...comparisonFilters, end_date: e.currentTarget.value })}
            />
          </div>

          <div className="Input-Container">
            <label className="Label">Comparison Course:</label>
            <select
              className="Input"
              defaultValue={comparisonFilters.course_id}
              onChange={(e) => setComparisonFilters({ ...comparisonFilters, course_name: courses[e.currentTarget.value]?.name, course_id: e.currentTarget.value })}
            >
              <option key="comparison-course-0" value="" children="All" />
              {
                Object.values(courses).map((course, idx) => (
                  <option key={`comparison-course-${idx}`} value={course.id} children={course.name} />
                ))
              }
            </select>
          </div>
        </div>
      </div>

      <div className="Actions">
        <ButtonBox
          className="Primary-Btn Generate-Report"
          text={<span className="Primary-Btn-Text">Generate & Download Report</span>}
          onClick={(e) => handleGenerateReport(e)}
        />
      </div>
    </div>
  )
}

Institutions.propTypes = {
  courses: PropTypes.object,
  fetchCourses: PropTypes.func.isRequired,
  total_licenses: PropTypes.number,
  processed_licenses: PropTypes.number,
  clearTEAdminInstitutionsStats: PropTypes.func.isRequired,
  fetchTEAdminInstitutionsStats: PropTypes.func.isRequired,
}

export default Institutions;
