import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';

import Loader from '../../Composed/Loader/Loader';

import { clearInstitutions, fetchInstitutions } from '../../../Actions/InstitutionActions';
import { updateAccount } from '../../../Actions/SessionActions';
import { isB2BAdmin } from '../../../Util/Helper/UserWorkplaceUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './InstitutionSwitch.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  institutions: (state.admin.institutions || {}),
});

const mapDispatchToProps = (dispatch) => ({
  clearInstitutions: () => dispatch(clearInstitutions()),
  fetchInstitutions: () => dispatch(fetchInstitutions()),
  updateAccount: user => dispatch(updateAccount(user)),
});

const InstitutionSwitch = ({ currentUser, institutions, clearInstitutions, fetchInstitutions, updateAccount }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isB2BAdmin(currentUser) && currentUser.is_internal_b2b_admin) {
      clearInstitutions();
      fetchInstitutions().then(() => setLoading(false));
    }
  }, [clearInstitutions, fetchInstitutions]);

  if (isB2BAdmin(currentUser) && currentUser.is_internal_b2b_admin) {
    return (
      <div className="InstitutionSwitch-Container">
        {
          loading ? <Loader /> : (
            <select
              className="Switcher"
              value={currentUser.institution_id}
              onChange={e => {
                setLoading(true);
                gaEvent('institution_switch', { user_id: currentUser.id, from_institution: currentUser.institution_id, to_institution: e.currentTarget.value });
                updateAccount({ id: currentUser.id, institution_id: e.currentTarget.value }).then(
                  res => window.location.reload()
                )
              }}
            >
              {
                sortBy(Object.values(institutions), i => i?.name?.toLowerCase()).map((institution, idx) => (
                  <option
                    key={`institution-${idx}`}
                    value={institution.id}
                    children={institution.name}
                  />
                ))
              }
            </select>
          )
        }
      </div>
    )
  }

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSwitch);
