import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNumberFormat from 'react-number-format';
import { MoonLoader } from 'react-spinners';

import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';

import { microsoftAdScript } from '../../../Util/Helper/MicrosoftUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';
import { clearCoursePackages, fetchProducts } from '../../../Actions/CoursePackageActions';
import { fetchInventories } from '../../../Actions/InventoryActions';
import { courseLanguageList } from '../../../Constants/CourseConstants';

import './Packages.scss';

const mapStateToProps = state => ({
  coursePackages: state.entities.coursePackages,
  inventories: state.entities.inventories,
});

const mapDispatchToProps = dispatch => ({
  clearCoursePackages: () => dispatch(clearCoursePackages()),
  fetchProducts: () => dispatch(fetchProducts()),
  fetchInventories: () => dispatch(fetchInventories()),
});

const {
  REACT_APP_ONE_DOMAIN,
} = process.env;

const Packages = ({
  coursePackages, clearCoursePackages, fetchProducts,
  inventories, fetchInventories,
}) => {
  const [loadLearn, setLoadLearn] = useState(true);
  const [loadCredential, setLoadCredential] = useState(true);
  const [language, setLanguage] = useState('SP');

  useEffect(() => {
    microsoftAdScript();
  }, []);

  useEffect(() => {
    clearCoursePackages();
    fetchProducts().then(res => setLoadLearn(false));
  }, [clearCoursePackages, fetchProducts]);

  useEffect(() => {
    fetchInventories().then(res => setLoadCredential(false));
  }, [fetchInventories]);

  const handleLearnSwitch = e => {
    setLanguage(e.currentTarget.checked ? 'EN' : 'SP')
  }

  const renderLoader = () => (
    <div className="Products-Loader">
      <MoonLoader color="#000" />
    </div>
  )

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const renderFullLearnPackages = () => (
    // Change the sort order before PR
    Object.values(coursePackages).sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)).map((coursePackage, idx) => (
      coursePackage.course_languages?.includes(language) && (coursePackage.name === 'All 3 Levels')
      && (
        <div
          className={classNames(
            coursePackage.length === 12 ? 'relative bg-white shadow-2xl' : 'bg-white/90 sm:mx-8 lg:mx-0',
            coursePackage.length === 12
              ? ''
              : idx === 0
                ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
            'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
          )}
          key={`learn-package-${idx}`}
        >
          <div>
            <div className="flex items-center justify-between gap-x-4">
              <h3 className="text-sm font-semibold leading-7 text-teal-600">
                {`Medical ${courseLanguageList[language]}`}
              </h3>
              <p className="rounded-full bg-teal-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-teal-600">{coursePackage.tag}</p>
            </div>
            <div className="mt-4">
              <TextBox
                className="text-3xl font-bold text-gray-900"
                text={coursePackage.name}
                tag="h5"
              />
            </div>
            <p className="mt-6 text-base leading-7 text-gray-600">{`${coursePackage.length} Month Subscription`}</p>
            <p
              className="ql-editor mt-10 text-sm leading-6 text-gray-600"
              dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(coursePackage.description) }}
            />
          </div>
          <div className={coursePackage.length === 12 ? 'mt-20' : ''} />
          <Link to={`/start-learning/package/${coursePackage.id}`}>
            <ButtonBox
              className="w-full mt-8 block rounded-md bg-teal-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              text={`Get started at $${coursePackage.price / 100}`}
              onClick={() => gaEvent('onboarding_package_select', { course_package_id: coursePackage.id })}
            />
          </Link>
        </div>
      )
    ))
  )

  const renderIndividualLearnPackages = () => (
    Object.values(coursePackages).sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)).map((coursePackage, idx) => (
      coursePackage.course_languages?.includes(language) && !coursePackage.tag
          && (
            <div
              className="bg-white ring-1 ring-gray-200 rounded-3xl p-8 xl:p-10"
              key={`learn-package-${idx}`}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3 className="text-sm font-semibold leading-7 text-teal-600">
                  {`Medical ${courseLanguageList[language]}`}
                </h3>
              </div>
              <div className="mt-4">
                <TextBox
                  className="text-gray-900 text-lg font-semibold leading-8"
                  text={coursePackage.name}
                  tag="h5"
                />
              </div>
              <p className="mt-6 text-base leading-7 text-gray-600">{`${coursePackage.length} Month Subscription`}</p>
              <p
                className="ql-editor mt-10 space-y-4 text-sm leading-6 text-gray-600"
                dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(coursePackage.description) }}
              />
              <Link to={`/start-learning/package/${coursePackage.id}`}>
                <ButtonBox
                  className="w-full mt-4 block rounded-md bg-teal-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  text={`Get started at $${coursePackage.price / 100}`}
                  onClick={() => gaEvent('onboarding_package_select', { course_package_id: coursePackage.id })}
                />
              </Link>
            </div>
          )
    ))
  )

  const renderBundledLearnPackages = () => (
    Object.values(coursePackages).sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)).map((coursePackage, idx) => (
      coursePackage.course_languages?.includes(language) && (coursePackage.tag === 'Best Value')
          && (
            <div
              className="lg:mx-0 lg:flex lg:max-w-none bg-white rounded-3xl ring-1 ring-gray-200"
              key={`learn-package-${idx}`}
            >
              <div className="p-8 sm:p-10 lg:flex-auto">
                <div className="flex items-center justify-between gap-x-4">
                  <h3 className="text-sm font-semibold leading-7 text-teal-600">
                    {`Medical ${courseLanguageList[language]}`}
                  </h3>
                </div>
                <div className="mt-4">
                  <TextBox
                    className="text-2xl font-bold tracking-tight text-gray-900"
                    text={coursePackage.name}
                    tag="h5"
                  />
                </div>
                <p className="mt-6 text-base leading-7 text-gray-600">{`${coursePackage.length} Month Subscription`}</p>
                <p
                  className="ql-editor mt-10 space-y-4 text-sm leading-6 text-gray-600"
                  dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(coursePackage.description) }}
                />
                <div className="mt-8 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-teal-600">What's included?</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul className="mt-8 grid grid-cols-1 gap-4 leading-6 sm:grid-cols-2 sm:gap-6">
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Level 1</li>
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Level 2</li>
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Level 3</li>
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Medical Proficiency Exam</li>
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical English Medical Proficiency Exam</li>
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="rounded-full bg-teal-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-teal-600">{coursePackage.tag}</p>
                    <p className="mt-4 flex items-baseline justify-center gap-x-2">
                      <span
                        className="text-5xl font-bold tracking-tight text-gray-900"
                      >
                        $
                        {coursePackage.price / 100}
                      </span>
                    </p>
                    <Link to={`/start-learning/package/${coursePackage.id}`}>
                      <ButtonBox
                        className="w-full mt-8 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        text="Get started"
                        onClick={() => gaEvent('onboarding_package_select', { course_package_id: coursePackage.id })}
                      />
                    </Link>
                    <p className="mt-6 text-xs leading-5 text-gray-600">
                      Invoices and receipts available for easy company reimbursement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
    ))
  )

  const renderFoundationalLearnPackages = () => (
    Object.values(coursePackages).sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)).map((coursePackage, idx) => (
      coursePackage.course_languages?.includes(language) && coursePackage.tag && (coursePackage.name.includes('Foundational'))
          && (
            <div
              className="lg:mx-0 lg:flex lg:max-w-none bg-white rounded-3xl ring-1 ring-gray-200"
              key={`learn-package-${idx}`}
            >
              <div className="p-8 sm:p-10 lg:flex-auto">
                <div className="flex items-center justify-between gap-x-4">
                  <h3 className="text-sm font-semibold leading-7 text-teal-600">
                    {`Medical ${courseLanguageList[language]}`}
                  </h3>
                </div>
                <div className="mt-4">
                  <TextBox
                    className="text-2xl font-bold tracking-tight text-gray-900"
                    text={coursePackage.name}
                    tag="h5"
                  />
                </div>
                <p className="mt-6 text-base leading-7 text-gray-600">{`${coursePackage.length} Month Subscription`}</p>
                <p
                  className="ql-editor mt-10 space-y-4 text-sm leading-6 text-gray-600"
                  dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(coursePackage.description) }}
                />
                <div className="mt-8 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-teal-600">What's included?</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul className="mt-8 grid grid-cols-1 gap-4 leading-6 sm:grid-cols-2 sm:gap-6">
                  <li className="flex gap-x-3 text-sm text-gray-600">Foundational Medical Spanish</li>
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Level 1</li>
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Level 2</li>
                  <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Level 3</li>
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="rounded-full bg-teal-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-teal-600">{coursePackage.tag}</p>
                    <p className="mt-4 flex items-baseline justify-center gap-x-2">
                      <span
                        className="text-5xl font-bold tracking-tight text-gray-900"
                      >
                        $
                        {coursePackage.price / 100}
                      </span>
                    </p>
                    <Link to={`/start-learning/package/${coursePackage.id}`}>
                      <ButtonBox
                        className="w-full mt-8 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        text="Get started"
                        onClick={() => gaEvent('onboarding_package_select', { course_package_id: coursePackage.id })}
                      />
                    </Link>
                    <p className="mt-6 text-xs leading-5 text-gray-600">
                      Invoices and receipts available for easy company reimbursement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
    ))
  )

  const renderCredentialInventories = () => (
    Object.values(inventories).map((inventory, idx) => (
      <div
        className="lg:mx-0 lg:flex lg:max-w-none bg-white rounded-3xl ring-1 ring-gray-200"
        key={`credential-inventory-${idx}`}
      >
        <div className="p-8 sm:p-10 lg:flex-auto">
          <div className="flex items-center justify-between gap-x-4">
            <h3 className="text-sm font-semibold leading-7 text-teal-600">
              {inventory.name}
            </h3>
          </div>
          <div className="mt-4">
            <TextBox
              className="text-2xl font-bold tracking-tight text-gray-900"
              text={inventory.variant}
              tag="h5"
            />
          </div>
          <p className="mt-6 text-base leading-7 text-gray-600">{inventory.description}</p>
          <div className="mt-8 flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-semibold leading-6 text-teal-600">What's included?</h4>
            <div className="h-px flex-auto bg-gray-100" />
          </div>
          <ul className="mt-8 grid grid-cols-1 gap-4 leading-6 sm:grid-cols-2 sm:gap-6">
            <li className="flex gap-x-3 text-sm text-gray-600">Medical Spanish Medical Proficiency Exam</li>
            <li className="flex gap-x-3 text-sm text-gray-600">Medical English Medical Proficiency Exam</li>
            <li className="flex gap-x-3 text-sm text-gray-600">Certificate of Bilingual Proficiency</li>
          </ul>
        </div>
        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div
            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16"
          >
            <div className="mx-auto max-w-xs px-8">
              <p className="rounded-full bg-teal-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-teal-600">{inventory.banner}</p>
              <p className="mt-4 flex items-baseline justify-center gap-x-2">
                <span
                  className="text-5xl font-bold tracking-tight text-gray-900"
                >
                  {inventory.price === 0 ? 'FREE' : `$ ${(inventory.price / 100)}`}
                </span>
              </p>
              <ButtonBox
                className="w-full mt-8 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                text="Get started"
                onClick={e => {
                  gaEvent('onboarding_inventory_select', { inventory_id: inventory.id });
                  window.location = `${REACT_APP_ONE_DOMAIN}/checkout/${inventory.id}`
                }}
              />
              <p className="mt-6 text-xs leading-5 text-gray-600">
                Invoices and receipts available for easy company reimbursement
              </p>
            </div>
          </div>
        </div>
      </div>
    ))
  )

  return (
    <>
      <div className="Packages-Container isolate overflow-hidden bg-gray-900">
        <div className="Head mx-auto max-w-7xl px-6 pb-96 pt-52 text-center sm:pt-44 lg:px-8">
          <div className="mx-auto max-w-7xl">
            <TextBox
              className="mt-2 text-4xl font-bold tracking-normal text-white sm:text-5xl"
              text={`Improve your Medical ${courseLanguageList[language]} with Canopy`}
              tag="h1"
            />
          </div>
          <div className="relative mt-6">
            <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
              Canopy is a proven, all-in-one platform that enables you to invest in yourself to provide the
              highest-quality patient care.
            </p>
            <div className="mt-16 flex justify-center">
              <div className="switch-button">
                <input className="switch-button-checkbox" type="checkbox" onClick={e => handleLearnSwitch(e)} />
                <label className="switch-button-label">
                  <span
                    className="switch-button-label-span"
                  >
                    SPANISH
                  </span>
                </label>
              </div>
            </div>
            <svg
              viewBox="0 0 1208 1024"
              className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
            >
              <ellipse cx={604} cy={512} rx={604} ry={512} fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" />
              <defs>
                <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                  <stop stopColor="#86efac" />
                  <stop offset={1} stopColor="#075985" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="Body flow-root bg-white pb-24 sm:pb-32">
          <div className="-mt-80">
            <div className="Learn mx-auto max-w-7xl px-6 lg:px-8">
              <div className="flex justify-center">
                <ImageBox src="logo_canopylearn.png" className="h-8" />
              </div>
              <p className="mt-10 text-sm leading-6 text-center text-white">
                The #1 medical language course in the
                US.
              </p>
              <p className="text-sm leading-6 text-center text-white">
                Take our
                <a
                  href="https://withcanopy.com/level-placement"
                  target="_blank"
                  className="mx-1 underline decoration-solid"
                  rel="noreferrer"
                >
                  short quiz
                </a>
                to find the best product and course for your needs.
              </p>
              <div
                className="FullLearnPackages mx-auto mt-8 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2"
              >
                {
                  loadLearn ? renderLoader() : renderFullLearnPackages()
                }
              </div>
              <div
                className="IndividualLearnPackages mx-auto mt-8 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:mx-0 lg:max-w-none xl:grid-cols-3"
              >
                {
                  loadLearn ? renderLoader() : renderIndividualLearnPackages()
                }
              </div>
              <div className="BundledLearnPackages mx-auto mt-8">
                {
                  loadLearn ? renderLoader() : renderBundledLearnPackages()
                }
              </div>
              <div className="FoundationalLearnPackages mx-auto mt-8">
                {
                  loadLearn ? renderLoader() : renderFoundationalLearnPackages()
                }
              </div>
            </div>
            <div className="Credential mt-20 mx-auto max-w-7xl px-6 lg:px-8">
              <div className="flex justify-center">
                <ImageBox src="logo_canopycredential.png" className="h-8" />
              </div>
              <p className="mt-10 text-sm leading-6 text-center text-gray-600">
                Advocate for your patients as a bilingual provider.
              </p>
              <div className="mx-auto mt-8">
                {
                  loadCredential ? renderLoader() : renderCredentialInventories()
                }
              </div>
            </div>
            <div className="Speak mt-20 mx-auto max-w-7xl px-6 lg:px-8">
              <div className="flex justify-center">
                <ImageBox src="logo_canopyspeak.png" className="h-8" />
              </div>
              <p className="mt-10 text-sm leading-6 text-center text-gray-600">
                Learn, practice, and apply your medical language skills in real patient-clinician scenarios.
              </p>
              <div className="mx-auto mt-8">
                {/* Static - delete later */}
                <div
                  className="max-w-2xl lg:mx-0 lg:flex lg:max-w-none rounded-3xl ring-1 ring-gray-200"
                >
                  <div className="p-8 sm:p-10 lg:flex-auto">
                    <div className="flex items-center justify-between gap-x-4">
                      <h3 className="text-sm font-semibold leading-7 text-teal-600">
                        Medical Spanish + Medical English
                      </h3>
                    </div>
                    <div className="mt-4">
                      <TextBox
                        className="text-2xl font-bold tracking-tight text-gray-900"
                        text="Premium Access"
                        tag="h5"
                      />
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">Monthly Subscription</p>
                    <p className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      Learn 2000+ medical phrases on-the-go in Spanish and Chinese on iOS
                    </p>
                    <div className="mt-8 flex items-center gap-x-4">
                      <h4 className="flex-none text-sm font-semibold leading-6 text-teal-600">What's included?</h4>
                      <div className="h-px flex-auto bg-gray-100" />
                    </div>
                    <ul className="mt-8 grid grid-cols-1 gap-4 leading-6 sm:grid-cols-2 sm:gap-6">
                      <li className="flex gap-x-3 text-sm text-gray-600">Clinical Mode for 2000+ medical phrases</li>
                      <li className="flex gap-x-3 text-sm text-gray-600">Learning Mode for 2000+ medical phrases</li>
                    </ul>
                  </div>
                  <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <div
                      className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16"
                    >
                      <div className="mx-auto max-w-xs px-8">
                        <p className="mt-6 flex items-baseline justify-center gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">$10</span>
                          <span className="text-base font-semibold leading-7 text-gray-600">/month</span>
                        </p>
                        <a
                          href="https://apps.apple.com/us/app/canopyspeak-premium/id6449747196"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonBox
                            className="w-full mt-8 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            text="Try out demo"
                            onClick={() => gaEvent('onboarding_speak_select')}
                          />
                        </a>
                        <p className="mt-6 text-xs leading-5 text-gray-600">
                          Currently, only available for iOS and iPadOS. Android coming soon!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
