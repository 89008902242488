import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import ReactTooltip from 'react-tooltip';
import confetti from 'canvas-confetti';
import WaveSurfer from 'wavesurfer.js';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Loader from '../../Composed/Loader/Loader';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';

import { sliceAudioBuffer } from '../../../Util/Helper/AudioUtil';
import { createPronunciationAssessment } from '../../../Actions/PronunciationAssessmentActions';

import './AudioAnalyzeButton.scss';

const {
  REACT_APP_AZURE_SPEECH_KEY,
  REACT_APP_AZURE_SPEECH_REGION,
} = process.env;

const mapDispatchToProps = dispatch => ({
  createPronunciationAssessment: pronunciationAssessment => dispatch(createPronunciationAssessment(pronunciationAssessment)),
});

const AudioAnalyzeButton = ({
  language, blob, url, text, entity, entityType,
  isReadingMode = true, isSpeakingMode = false,
  createPronunciationAssessment,
}) => {
  const [open, setOpen] = useState(false);
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState({});
  const [generatingFeedback, setGeneratingFeedback] = useState(false);
  const [feedback, setFeedback] = useState({});

  useEffect(() => {
    if (Object.keys(result).length > 0) {
      if (!anyBadScore()) {
        fireConfetti();
      }

      trackResult();
      handleFeedback();
    }
  }, [result]);

  useEffect(() => {
    Object.values(feedback).map((wordFeedback, idx) => {
      if (wordFeedback.userURL) {
        WaveSurfer.create({
          container: `#Wave-Form-User-Recorded-${idx}`,
          url: wordFeedback.userURL,
          height: 56,
          waveColor: '#e0524a',
          progressColor: '#e0524a',
          cursorWidth: 0,
        })
      }

      if (wordFeedback.aiURL) {
        WaveSurfer.create({
          container: `#Wave-Form-AI-Recorded-${idx}`,
          url: wordFeedback.aiURL,
          height: 56,
          waveColor: '#6bd1be',
          progressColor: '#6bd1be',
          cursorWidth: 0,
        })
      }
    });
  }, [feedback]);

  const trackResult = () => {
    const file = new File([blob], 'file', { type: 'audio/wav' })

    const resultToTrack = result.Words.reduce((acc, word) => {
      acc.Words.push({
        Word: word.Word,
        Offset: word.Offset,
        Duration: word.Duration,
        PronunciationAssessment: {
          AccuracyScore: word.PronunciationAssessment.AccuracyScore,
          ErrorType: word.PronunciationAssessment.ErrorType,
        },
      });

      return acc;
    }, { PronunciationAssessment: result.PronunciationAssessment, Words: [] });

    const formData = new FormData();
    formData.append('pronunciation_assessment[assessmentable_type]', entityType);
    formData.append('pronunciation_assessment[assessmentable_id]', entity.id);
    formData.append('pronunciation_assessment[language]', language);
    formData.append('pronunciation_assessment[text]', text);
    formData.append('pronunciation_assessment[mode]', isReadingMode ? 'reading' : 'speaking');
    formData.append('pronunciation_assessment[result]', JSON.stringify(resultToTrack));
    formData.append('pronunciation_assessment[audio]', file);

    createPronunciationAssessment(formData);
  }

  const handleAnalyze = async () => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(REACT_APP_AZURE_SPEECH_KEY, REACT_APP_AZURE_SPEECH_REGION);
    speechConfig.speechRecognitionLanguage = language;

    const pushStream = sdk.AudioInputStream.createPushStream();
    const arrayBuffer = await blob.arrayBuffer();
    pushStream.write(new Uint8Array(arrayBuffer));
    pushStream.close();
    const audioConfig = sdk.AudioConfig.fromStreamInput(pushStream);

    const assessmentConfig = new sdk.PronunciationAssessmentConfig(
      (isReadingMode ? text : ''),
      sdk.PronunciationAssessmentGradingSystem.HundredMark,
      sdk.PronunciationAssessmentGranularity.Phoneme,
      isReadingMode
    );
    assessmentConfig.enableProsodyAssessment = true;
    if (isSpeakingMode) {
      assessmentConfig.enableContentAssessmentWithTopic(text);
    }

    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    assessmentConfig.applyTo(recognizer);

    recognizer.recognized = (s, e) => {
      if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
        let paResult = sdk.PronunciationAssessmentResult.fromResult(e.result).privPronJson;

        const customAccuracyData = paResult.Words.reduce((acc, word) => {
          if (wordAssessmentHasInsertion(word)) {
            return acc;
          }

          acc.totalWords += 1;

          const isMispronounced = wordAssessmentHasMisPronunciation(word);
          acc.totalMispronouncedWords += isMispronounced ? 1 : 0;

          const wordScore = (word.PronunciationAssessment.AccuracyScore || 0);
          acc.totalWordsScore += isMispronounced ? (wordScore * mispronouncePenalty.AccuracyScore) : wordScore;

          return acc;
        }, { totalWords: 0, totalMispronouncedWords: 0, totalWordsScore: 0 });

        const customAccuracyScore = Math.round(customAccuracyData.totalWordsScore / customAccuracyData.totalWords);
        paResult.PronunciationAssessment.AccuracyScore = Math.min(paResult.PronunciationAssessment.AccuracyScore, customAccuracyScore);

        let customPronScore = Math.round(
          (paResult.PronunciationAssessment.AccuracyScore * scoreDimensions.AccuracyScore.weight)
          + (paResult.PronunciationAssessment.FluencyScore * scoreDimensions.FluencyScore.weight)
          + (paResult.PronunciationAssessment.CompletenessScore * scoreDimensions.CompletenessScore.weight)
        );
        customPronScore = Math.max(0, (customPronScore - (customAccuracyData.totalMispronouncedWords * mispronouncePenalty.PronScore)));
        paResult.PronunciationAssessment.PronScore = Math.min(paResult.PronunciationAssessment.PronScore, customPronScore);

        setError('');
        setResult(paResult);
      } else {
        setError('Your recording does not match with the presented text!');
      }
      setAnalyzing(false);
    };

    recognizer.recognizeOnceAsync((res) => {
        recognizer.close();
      }, (err) => {
        recognizer.close();
        setError('Unable to process recording at the moment!');
        setAnalyzing(false);
      }
    );
  }

  const handleFeedback = async () => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(REACT_APP_AZURE_SPEECH_KEY, REACT_APP_AZURE_SPEECH_REGION);
    speechConfig.speechSynthesisVoiceName = (language === 'es-MX' ? 'es-MX-DaliaNeural' : 'en-US-JennyNeural');

    const audioConfig = null;

    let newFeedback = {};
    const spokenWords = result.Words.filter(word => !wordAssessmentHasOmission(word))
    newFeedback.OriginalText = {
      original: true,
      word: text,
      userURL: url,
      offset: (spokenWords[0].Offset) - 1000000,
      duration: (spokenWords[spokenWords.length - 1].Offset + spokenWords[spokenWords.length - 1].Duration),
    };
    result.Words.filter(word => wordAssessmentHasMisPronunciation(word)).map(word => (
      newFeedback[word.Word] = { original: false, word: word.Word, offset: word.Offset, duration: word.Duration }
    ));

    const aiPromises = Object.entries(newFeedback).map(([feedbackKey, feedbackValue]) => {
      return new Promise((resolve, reject) => {
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

        synthesizer.speakTextAsync(
          feedbackValue.word,
          (res) => {
            if (res.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
              const ttsBlob = new Blob([res.audioData], { type: 'audio/wav' });
              const ttsURL = URL.createObjectURL(ttsBlob);
              synthesizer.close();

              newFeedback[feedbackKey].aiURL = ttsURL;
              resolve();
            } else {
              synthesizer.close();
              newFeedback[feedbackKey].aiError = 'Unable to provide reference speech!';
              resolve();
            }
          },
          (err) => {
            synthesizer.close();
            newFeedback[feedbackKey].aiError = 'Unable to provide reference speech!';
            resolve();
          }
        )
      })
    });
    await Promise.all(aiPromises);

    for (let i = 0; i < Object.keys(newFeedback).length; i++) {
      const [feedbackKey, feedbackValue] = Object.entries(newFeedback)[i];
      const startTime = (feedbackValue.offset / 10000000);
      const endTime = startTime + (feedbackValue.duration / 10000000);

      const userBlob = await sliceAudioBuffer(blob, startTime, endTime);

      if (userBlob) {
        newFeedback[feedbackKey].userURL = URL.createObjectURL(userBlob);
      } else {
        if (!feedbackValue.original) {
          newFeedback[feedbackKey].userError = 'Unable to provide your recorded speech!';
        }
      }
    }

    setFeedback(newFeedback);
    setGeneratingFeedback(false);
  }

  const scoreDimensions = {
    'AccuracyScore':     { description: 'Accuracy',      weight: 0.7,  tooltip: 'Pronunciation accuracy of the speech. Accuracy indicates how closely the phonemes match a native speaker\'s pronunciation.' },
    'FluencyScore':      { description: 'Fluency',       weight: 0.15, tooltip: 'Fluency of the given speech. Fluency indicates how closely the speech matches a native speaker\'s use of silent breaks between words.' },
    'CompletenessScore': { description: 'Completeness',  weight: 0.15, tooltip: 'Completeness of the speech, calculated by the ratio of pronounced words to the presented text.' },
    'PronScore':         { description: 'Pronunciation',               tooltip: 'Overall score indicating the pronunciation quality of the given speech.' },
  }

  const mispronouncePenalty = {
    'AccuracyScore': 0.5,
    'PronScore': 3,
  }

  const scoreRanges = {
    bad:     Array(60).fill(0).map((x, y) => x + y),
    average: Array(20).fill(0).map((x, y) => x + y + 60),
    good:    Array(21).fill(0).map((x, y) => x + y + 80),
  }

  const anyBadScore = () => (
    Object.values(result.PronunciationAssessment)?.some(score => (
      !scoreRanges.good.includes(Math.ceil(score))
    ))
  );

  const fireConfetti = () => {
    confetti({ origin: { y: 0.7 }, spread: 26, startVelocity: 55, particleCount: Math.floor(500 * 0.25) });
    confetti({ origin: { y: 0.7 }, spread: 60, particleCount: Math.floor(500 * 0.2) });
    confetti({ origin: { y: 0.7 }, spread: 100, decay: 0.91, scalar: 0.8, particleCount: Math.floor(500 * 0.35) });
    confetti({ origin: { y: 0.7 }, spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2, particleCount: Math.floor(500 * 0.1) });
    confetti({ origin: { y: 0.7 }, spread: 120, startVelocity: 45, particleCount: Math.floor(500 * 0.1) });
  }

  const wordAssessmentHasMisPronunciation = (word) => (
    word.PronunciationAssessment.ErrorType === 'Mispronunciation'
    || (word.PronunciationAssessment.ErrorType === 'None' && word.PronunciationAssessment.AccuracyScore < 85)
  );

  const wordAssessmentHasInsertion = (word) => (
    word.PronunciationAssessment.ErrorType === 'Insertion'
  );

  const wordAssessmentHasOmission = (word) => (
    word.PronunciationAssessment.ErrorType === 'Omission'
  );

  const wordAssessmentProsodyHasUnexpectedBreak = (word) => (
    word.PronunciationAssessment.Feedback?.Prosody?.Break?.UnexpectedBreak?.Confidence > 0.75
  );

  const wordAssessmentProsodyHasMissingBreak = (word) => (
    word.PronunciationAssessment.Feedback?.Prosody?.Break?.MissingBreak?.Confidence > 0.99
  );

  const wordAssessmentProsodyHasMonotoneIntonation = (word) => (
    word.PronunciationAssessment.Feedback?.Prosody?.Intonation?.ErrorTypes?.includes('Monotone')
  );

  const getWordClasses = (word) => {
    let classes = 'Word ';

    classes += (wordAssessmentHasMisPronunciation(word) ? 'Mispronunciation ' : '');
    if (isReadingMode) {
      classes += (wordAssessmentHasInsertion(word) ? 'Insertion ' : '');
      classes += (wordAssessmentHasOmission(word) ? 'Omission ' : '');
    }
    classes += (wordAssessmentProsodyHasUnexpectedBreak(word) ? 'UnexpectedBreak ' : '');
    // classes += wordAssessmentProsodyHasMissingBreak(word) ? 'MissingBreak ' : '';
    // classes += wordAssessmentProsodyHasMonotoneIntonation(word) ? 'Monotone ' : '';

    return classes;
  }

  const renderResult = () => {
    if (analyzing) {
      return <Loader message="Analyzing your recording..." />
    }

    if (error.length > 0) {
      return <div className="Error ErrorsFlashBox-Error">{error}</div>
    }

    return (
      <div className="Result-Container">
        <div className="Summary-Level-Result">
          <div className="Title">
            Summary
          </div>

          <div className="Legends">
            <div className="Legend Good">
              <div className="Box"></div>
              <div className="Text">Good</div>
            </div>
            <div className="Legend Average">
              <div className="Box"></div>
              <div className="Text">Average</div>
            </div>
            <div className="Legend Bad">
              <div className="Box"></div>
              <div className="Text">Poor</div>
            </div>
          </div>

          <div className="Summary-Section">
            <div className="ScoreDimensions">
              {
                Object.entries(result.PronunciationAssessment).map(([scoreDimension, score], idx) => {
                  let scoreRange = Object.entries(scoreRanges).find(([k, v]) => v.includes(Math.ceil(score)));

                  return (
                    <div key={`dimension-${idx}`} className={`ScoreDimension ${scoreRange[0]}`} style={{'--progressbar': `${score}`}}>
                      <div className="Dot"></div>

                      <svg>
                        <circle cx="70" cy="70" r="70"></circle>
                        <circle cx="70" cy="70" r="70"></circle>
                      </svg>

                      <div className="Percentage-Holder">
                        <div className="Percentage">{Math.ceil(score)}<span>%</span></div>
                      </div>

                      <div className="Label">
                        <span className="Text">
                          {scoreDimensions[scoreDimension].description} {scoreDimension === 'PronScore' ? '*' : ''}
                        </span>
                        <i className="fa fa-info Info-Icon" data-tip data-for={`Label-Tooltip-${idx}`} />
                        <ReactTooltip place="top" type="light" effect="solid" className="Label-Tooltip" id={`Label-Tooltip-${idx}`}>
                          {scoreDimensions[scoreDimension].tooltip}
                        </ReactTooltip>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div className="Formula">
              <p className="Text">
                * Your pronunciation is calculated based on these weighted scores:&nbsp;
                <span className="Code">Accuracy ({scoreDimensions.AccuracyScore.weight * 100}%), Fluency ({scoreDimensions.FluencyScore.weight * 100}%) and Completeness ({scoreDimensions.CompletenessScore.weight * 100}%).</span>
              </p>
              <p className="Text">If you mispronounce a word, it affects the accuracy score, which in turn impacts the final pronunciation score. So, aiming for clear and accurate pronunciation is essential for a higher score.</p>
            </div>
          </div>
        </div>

        <div className="Word-Level-Result">
          <div className="Title">
            Details
          </div>

          <div className="Legends">
            <div className="Legend Mispronunciation">
              <div className="Box">{result.Words.filter(word => wordAssessmentHasMisPronunciation(word)).length}</div>
              <div className="Text">Mispronunciation</div>
              <i className="fa fa-info Info" data-tip data-for="Mispronunciation-Legend-Tooltip"></i>
              <ReactTooltip place="top" type="light" effect="solid" className="Tooltip" id="Mispronunciation-Legend-Tooltip">
                The words that are spoken incorrectly.
              </ReactTooltip>
            </div>

            {
              isReadingMode
              && (
                <>
                  <div className="Legend Insertion">
                    <div className="Box">{result.Words.filter(word => wordAssessmentHasInsertion(word)).length}</div>
                    <div className="Text">Insertions</div>
                    <i className="fa fa-info Info" data-tip data-for="Insertion-Legend-Tooltip"></i>
                    <ReactTooltip place="top" type="light" effect="solid" className="Tooltip" id="Insertion-Legend-Tooltip">
                      The words that are not in the text but are detected in the recording.
                    </ReactTooltip>
                  </div>

                  <div className="Legend Omission">
                    <div className="Box">{result.Words.filter(word => wordAssessmentHasOmission(word)).length}</div>
                    <div className="Text">Omissions</div>
                    <i className="fa fa-info Info" data-tip data-for="Omission-Legend-Tooltip"></i>
                    <ReactTooltip place="top" type="light" effect="solid" className="Tooltip" id="Omission-Legend-Tooltip">
                      The words that are in the text but are not spoken.
                    </ReactTooltip>
                  </div>
                </>
              )
            }

            <div className="Legend UnexpectedBreak">
              <div className="Box">{result.Words.filter(word => wordAssessmentProsodyHasUnexpectedBreak(word)).length}</div>
              <div className="Text">Unexpected Break</div>
              <i className="fa fa-info Info" data-tip data-for="UnexpectedBreak-Legend-Tooltip"></i>
              <ReactTooltip place="top" type="light" effect="solid" className="Tooltip" id="UnexpectedBreak-Legend-Tooltip">
                Improperly paused in between words.
              </ReactTooltip>
            </div>

            {/*<div className="Legend MissingBreak">
              <div className="Box">{result.Words.filter(word => wordAssessmentProsodyHasMissingBreak(word)).length}</div>
              <div className="Text">Missing Break</div>
              <i className="fa fa-info Info" data-tip data-for="MissingBreak-Legend-Tooltip"></i>
              <ReactTooltip place="top" type="light" effect="solid" className="Tooltip" id="MissingBreak-Legend-Tooltip">
                Missing pauses between words.
              </ReactTooltip>
            </div>*/}

            {/*<div className="Legend Monotone">
              <div className="Box">{result.Words.filter(word => wordAssessmentProsodyHasMonotoneIntonation(word)).length}</div>
              <div className="Text">Monotone</div>
              <i className="fa fa-info Info" data-tip data-for="Monotone-Legend-Tooltip"></i>
              <ReactTooltip place="top" type="light" effect="solid" className="Tooltip" id="Monotone-Legend-Tooltip">
                The words are being read in a flat and unexciting tone, without any rhythm or expression.
              </ReactTooltip>
            </div>*/}
          </div>

          <div className="Word-Section">
            <div className="Words">
              {
                result.Words.map((word, idx) => (
                  <div key={`word-result-${idx}`} className={getWordClasses(word)}>
                    <span className="Text">
                      {word.Word}
                    </span>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderFeedback = () => {
    if (analyzing || (error.length > 0)) {
      return null;
    }

    if (generatingFeedback) {
      return <Loader message="Generating feedback..." />
    }

    if (Object.keys(feedback).length === 0) {
      return null;
    }

    return (
      <div className="Feedback-Container">
        <div className="Word-Level-Feedback">
          <div className="Title">
            Correct Pronunciations
          </div>

          <div className="Word-Section">
            <div className="Words">
              {
                Object.entries(feedback).map(([word, wordFeedback], idx) => (
                  <div key={`word-feedback-${idx}`} className={`Word ${wordFeedback.original ? 'Original' : ''}`}>
                    {
                      !wordFeedback.original
                      && (
                        <div className="Text-Box">
                          {word}
                        </div>
                      )
                    }
                    <div className="Clip-Box">
                      <div className="User-Recorded">
                        {
                          wordFeedback.userError ? (
                            <div className="Error-Box">{wordFeedback.userError}</div>
                          ) : (
                            <>
                              <span className="Text">Your Pronunciation</span>
                              <AudioPlayButton src={wordFeedback.userURL} />
                            </>
                          )
                        }
                      </div>
                      <div className="AI-Recorded">
                        {
                          wordFeedback.aiError ? (
                            <div className="Error-Box">{wordFeedback.aiError}</div>
                          ) : (
                            <>
                              <span className="Text">Correct Pronunciation</span>
                              <AudioPlayButton src={wordFeedback.aiURL} />
                            </>
                          )
                        }
                      </div>
                    </div>
                    <div className="Wave-Box">
                      <div className="User-Recorded" id={`Wave-Form-User-Recorded-${idx}`} />
                      <div className="AI-Recorded" id={`Wave-Form-AI-Recorded-${idx}`} />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!open) {
    return (
      <div className="AudioAnalyzeButton-Container">
        <ButtonBox
          className="Analyze-Btn"
          text={<i className="fa-solid fa-language" />}
          onClick={() => {
            setOpen(true);
            setAnalyzing(true);
            setError('');
            setResult({});
            setGeneratingFeedback(true);
            setFeedback({});
            handleAnalyze();
          }}
        />
      </div>
    )
  }

  return (
    <div className="AudioAnalyzeButton-Container">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <p className="Title">Pronunciation Feedback</p>
            <ButtonBox
              className="Close-Btn"
              src="close.svg"
              onClick={() => {
                setOpen(false);
                setAnalyzing(false);
                setError('');
                setResult({});
                setGeneratingFeedback(false);
                setFeedback({});
              }}
            />
          </div>

          <div className="modal-body">
            {renderResult()}
            {renderFeedback()}
          </div>
        </div>
      </div>
    </div>
  )
}

AudioAnalyzeButton.propTypes = {
  language: PropTypes.string.isRequired,
  blob: PropTypes.instanceOf(Blob),
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  entityType: PropTypes.string.isRequired,
  isReadingMode: PropTypes.bool,
  isSpeakingMode: PropTypes.bool,
  createPronunciationAssessment: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AudioAnalyzeButton);
