import audioBufferToWav from 'audiobuffer-to-wav';

export const sliceAudioBuffer = async (blob, startTime, endTime) => {
  let slicedBlob;

  try {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    if (startTime < 0) {
      startTime = 0;
    }

    if (endTime > audioBuffer.duration) {
      endTime = audioBuffer.duration;
    }

    const startOffset = startTime * audioBuffer.sampleRate;
    const endOffset = endTime * audioBuffer.sampleRate;
    const frameCount = Math.ceil(endOffset - startOffset);

    const slicedAudioBuffer = audioContext.createBuffer(audioBuffer.numberOfChannels, frameCount, audioBuffer.sampleRate);

    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      const sourceData = audioBuffer.getChannelData(channel);
      const targetData = slicedAudioBuffer.getChannelData(channel);
      targetData.set(sourceData.subarray(startOffset, endOffset));
    }

    const slicedArrayBuffer = audioBufferToWav(slicedAudioBuffer);
    slicedBlob = new Blob([slicedArrayBuffer], { type: 'audio/wav' })
  } catch (e) {
    slicedBlob = null
  }

  return slicedBlob;
}
