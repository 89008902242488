import React from 'react';
import { BeatLoader } from 'react-spinners';

import './Loader.scss';

const Loader = ({ message }) => (
  <div className="Content-Loader">
    {message && <div className="Message" children={message} />}
    <BeatLoader color="#000" />
  </div>
)

export default Loader;
