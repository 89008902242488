import React from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';

import './AlertPopup.scss';

const AlertPopup = ({ close, kind, title = '', messages = [] }) => {
  if (!close || !kind) {
    return null;
  }

  const iconClass = () => {
    switch (kind) {
      case 'success':
        return 'fa-check-circle Success-Icon';
      case 'error':
        return 'fa-times-circle Error-Icon';
      case 'information':
        return 'fa-info-circle Info-Icon';
      default:
        return '';
    }
  }

  return (
    <div className="modal AlertPopup">
      <div className="modal-content">
        <div className="Head">
          <ButtonBox
            className="Close-Btn"
            src="close.svg"
            onClick={() => close()}
          />
        </div>

        <div className="Body">
          <i className={`fa fa-5x Icon ${iconClass()}`} />
          {title && <h3 className="Title">{title}</h3>}
          {
            (messages.length > 0) && (
              <div className="Messages">
                {
                  messages.map((message, idx) => (
                    <div
                      key={idx}
                      className="Message"
                      dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(message) }}
                    />
                  ))
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

AlertPopup.propTypes = {
  close: PropTypes.func,
  kind: PropTypes.string,
  title: PropTypes.string,
  messages: PropTypes.array,
}

export default AlertPopup;
