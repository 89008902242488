import './CreateUser.css';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { sortBy } from 'lodash';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import RadioBox from '../../Basic/RadioBox/RadioBox';
import DashboardFormBoxNoEdit from '../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import AutoComplete from '../../Composed/AutoComplete/AutoComplete';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import Loader from '../../Composed/Loader/Loader';

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const GENERIC_ERROR = 'Please complete the form before submitting';
const MISSING_EMAIL = 'Please add a valid user email address and click Verify';
const MISSING_INSTITUTION = 'Please choose an institution';
const MISSING_LICENSE = 'Please choose a license to invite these users with';

const CreateUser = ({
    checkExistingUser,
    clearCreateUserErrors,
    createUser,
    createUserFormValidatonErrors,
    currentUser,
    errors,
    fetchInstitutions,
    flash,
}) => {
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [addressBuffer, setAddressBuffer] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [selectedLicenseId, setselectedLicenseId] = useState(null);
    const [selectedUserType, setSelectedUserType] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    const resetState = () => {
        // Assume state values set by useEffect are always current,
        // and that we only want to reset form related state values.
        setAddressBuffer('');
        setFormValid(false);
        setInstitutions([]);
        setLicenses([]);
        setSelectedInstitution(null);
        setselectedLicenseId(null);
        setSelectedUserType(null);
        setUserEmail(null);
    };

    // Assume that we want to fetch all the institutions for TE admins
    useEffect(() => {
        if (institutions?.length < 1) {
            fetchInstitutions().then(response => {
                setLoading(false);
                setInstitutions(Object.values(response.payload));
            });
        }
    }, [institutions, fetchInstitutions]);

    // Assume we want to automatically set the license list
    // every time the selected institutions change
    useEffect(() => {
        if (selectedInstitution?.licenses) {
            setLicenses(selectedInstitution.licenses);
        }
    }, [selectedInstitution]);

    // Assume if there is only one license, we will pre-select it
    useEffect(() => {
        if (licenses?.length === 1) {
            setselectedLicenseId(licenses[0].id);
        }
    }, [licenses]);

    // Assume we want to show different save button states
    // based on whether the form is completed or not
    useEffect(() => {
        if (selectedLicenseId && userEmail?.length > 0 && selectedInstitution && selectedUserType === 'B2B.User') {
            setFormValid(true);
        } else if (userEmail?.length > 0 && selectedInstitution && selectedUserType === 'B2B.Admin') {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [selectedLicenseId, userEmail, selectedInstitution, selectedUserType]);

    // Assume we want to clear any existing flash errors the first time this component loads
    useEffect(() => {
        clearCreateUserErrors();
    }, [clearCreateUserErrors]);

    const getFormErrorMessage = () => {
        const flashError = { message: GENERIC_ERROR };
        if (!userEmail || userEmail?.length < 1) {
            flashError.message = MISSING_EMAIL;
        } else if (!selectedInstitution?.id) {
            flashError.message = MISSING_INSTITUTION;
        } else if ((!selectedLicenseId || selectedLicenseId === '') && selectedUserType === 'B2B.User') {
            flashError.message = MISSING_LICENSE;
        }
        return flashError;
    };

    const setValidEmailAddress = (inputString) => {
        if (inputString != null && inputString !== '' && inputString !== ' ') {
            const emailWithoutSpaces = inputString.trim();
            const validEmailFormatArr = emailRegex.exec(emailWithoutSpaces);
            if (validEmailFormatArr && validEmailFormatArr.length > 0) {
                setProcessing(true);
                checkExistingUser({ email: validEmailFormatArr[0] }).then(response => {
                    setProcessing(false);
                    if (response.exists === false) {
                        setUserEmail(validEmailFormatArr[0]);
                    } else {
                        setUserEmail(null);
                    }
                });
            }
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        clearCreateUserErrors();
        resetState();
    };

    const handleEmailInputChange = (e) => {
        clearCreateUserErrors();
        setAddressBuffer(e.target.value);
    };

    const handleVerifyEmail = (e) => {
        e.preventDefault();
        clearCreateUserErrors();
        if (addressBuffer && addressBuffer.length > 0) {
            setValidEmailAddress(addressBuffer);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        clearCreateUserErrors();
        if (!formValid) {
            createUserFormValidatonErrors(getFormErrorMessage(e));
        } else {
            const user = {
                email: userEmail,
                institution_id: selectedInstitution.id,
                created_by_id: currentUser.id,
                user_type: selectedUserType,
                password: uuidv4(),
                first_name: 'unknown',
                last_name: 'unknown',
            };
            // Assume that admins don't have licenses, only regular users
            if (selectedUserType === 'B2B.User') { user.license_id = selectedLicenseId; }

            setProcessing(true);
            createUser(user).then(() => {
                setProcessing(false);
                resetState();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
        }
    };

    const handleLicenseChange = (e) => {
        clearCreateUserErrors();
        setselectedLicenseId(parseInt(e.currentTarget.value, 10));
    };

    const handleInstitutionChange = (e) => {
        clearCreateUserErrors();
        const matchingInstitution = institutions.filter(institution => (
            institution.id === e.value));
        if (matchingInstitution && matchingInstitution.length > 0) {
            setSelectedInstitution(matchingInstitution[0]);
        }
    };

    const handleUserTypeChange = (e) => {
        clearCreateUserErrors();
        if (e.currentTarget.value === 'B2B.Admin' || e.currentTarget.value === 'B2B.User') {
            setSelectedUserType(e.currentTarget.value);
        }
    };

    const buildLicenses = () => (
        <div className="CreationForms-RadioBox">
            {licenses?.map((license, index) => (
                <RadioBox
                    key={license.access_code}
                    checked={(selectedLicenseId === license.id)}
                    className="RadioBox-EditUser"
                    name={`license-choice-${index}`}
                    value={`${license.id}`}
                    text={license.access_code}
                    onChange={handleLicenseChange}
                />
            ))}
        </div>
    );

    const buildInstitutionDropdown = () => (
        <AutoComplete
            className="Institution-Auto-Complete"
            onChange={(e) => handleInstitutionChange(e)}
            defaultValue={{ label: selectedInstitution?.name, value: selectedInstitution?.id }}
            options={sortBy(institutions, i => i?.name?.toLowerCase())?.map((institution) => { return { value: institution.id, label: institution.name } })}
            isMulti={false}
        />
    );

    const buildUserTypeDropdown = () => (
        <select
            className="createLicenseContainer__select"
            onChange={handleUserTypeChange}
            value={selectedUserType || 'unselected'}
        >
            <option value="unselected" disabled>Select a User Type</option>
            <option value="B2B.Admin" key="B2B.Admin">B2B.Admin</option>
            <option value="B2B.User" key="B2B.User">B2B.User</option>
        </select>
    );


    if (loading) {
        return <Loader />
    }

    return (
        <div className="createUserContainer CreateUser">
            {processing && <MirrorLoader message="Processing..." />}
            <ErrorFlashBox
                errors={errors}
                flash={flash}
            />
            <form className="DashboardFormBox-Content" onSubmit={handleSubmit}>
                <DashboardFormBoxNoEdit
                    title="CREATE USER"
                >
                    <div className="DashboardFormBox-Line fdc">
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                            <div className="formWidth" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="DashboardFormBox-Label left" style={{ marginRight: 10 }}>
                                    User Email
                                </div>
                                <button
                                    className="CreateUser-Update-Button"
                                    onClick={handleVerifyEmail}
                                    type="button"
                                >
                                    Verify
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <input
                                className={`${userEmail ? 'ValidEmail' : ''} formWidth`}
                                onChange={handleEmailInputChange}
                                placeholder=""
                                style={{ marginTop: 0, marginBottom: 0 }}
                                type="text"
                                value={addressBuffer}
                            />
                            <i className={`fas fa-check ${userEmail ? 'ValidEmail' : ''}`} />
                        </div>
                        <div style={{
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        />
                    </div>
                </DashboardFormBoxNoEdit>
                <div style={{ marginTop: '-40px' }}>
                    <DashboardFormBoxNoEdit
                        title="USER TYPE"
                    >
                        <div className="DashboardFormBox-Line fdc">
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <div className="formWidth" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    { buildUserTypeDropdown() }
                                </div>
                            </div>
                        </div>
                    </DashboardFormBoxNoEdit>
                </div>
                <div style={{ marginTop: '-40px' }}>
                    <DashboardFormBoxNoEdit
                        title="ADD TO INSTITUTION"
                    >
                        <div className="DashboardFormBox-Line fdc">
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <div className="formWidth" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    { buildInstitutionDropdown() }
                                </div>
                            </div>
                        </div>
                    </DashboardFormBoxNoEdit>
                </div>
                { selectedUserType === 'B2B.User'
                && (
                    <div style={{ marginTop: '-40px' }}>
                        <DashboardFormBoxNoEdit
                            title="ADD TO LICENSE"
                        >
                            <div className="DashboardFormBox-Line fdc">
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                    <div className="formWidth" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        { buildLicenses() }
                                    </div>
                                </div>
                            </div>
                        </DashboardFormBoxNoEdit>
                    </div>
                )}
                <div style={{ marginTop: '-40px' }}>
                    <DashboardFormBoxNoEdit
                        hideTitle
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ButtonBox
                                className={`DashboardFormBox-SaveBtn DashboardFormBox-Button_save ${formValid ? '' : ' disabled_save_button'}`}
                                text="Create"
                            />
                            <ButtonBox
                                className="DashboardFormBox-Button_cancel DashboardFormBox-CancelBtn"
                                text="Clear"
                                onClick={handleCancel}
                            />
                        </div>
                    </DashboardFormBoxNoEdit>
                </div>
            </form>
        </div>
    );
};

CreateUser.propTypes = {
    clearCreateUserErrors: PropTypes.func.isRequired,
    checkExistingUser: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    createUserFormValidatonErrors: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
        id: PropTypes.number,
        user_type: PropTypes.string,
        institution_id: PropTypes.number,
    }).isRequired,
    errors: PropTypes.shape({
        message: PropTypes.string,
    }),
    fetchInstitutions: PropTypes.func.isRequired,
    flash: PropTypes.shape({
        message: PropTypes.string,
    }),
};

CreateUser.defaultProps = {
    errors: undefined,
    flash: undefined,
};

export default CreateUser;
