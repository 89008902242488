import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ImageBox from '../../../Basic/ImageBox/ImageBox';

import { clearFilters, receiveFilter } from '../../../../Actions/FilterActions';
import { fetchGroups } from '../../../../Actions/GroupActions';

import { courseSeriesList } from '../../../../Constants/CourseConstants';
import { timeIntervalsList } from '../../../../Constants/TimeConstants';
import { departmentsList } from '../../../../Constants/DepartmentConstants'
import { examStatusesList } from '../../../../Constants/CredentialConstants';

import './Filters.scss'

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  filters: state.filters || {},
  groups: state.admin.groups || {},
});

const mapDispatchToProps = (dispatch) => ({
  clearFilters: () => dispatch(clearFilters()),
  receiveFilter: (filter, value) => dispatch(receiveFilter(filter, value)),
  fetchGroups: () => dispatch(fetchGroups())
});

const Filters = ({
  currentUser, filters, groups,
  clearFilters, receiveFilter, fetchGroups,
  showFilters = [], forFilters, filtersLoaded,
}) => {
  const allFacilities = { '': 'Please Select', 'all_facilities': 'All Facilities', 'facilities_a': 'Facilitie A', 'facilities_b': 'Facilitie B', 'facilities_c': 'Facilitie C' };
  const allRoles = { '': 'Please Select', 'all_Roles': 'All Roles', 'role_a': 'Role A', 'role_b': 'Role B', 'role_c': 'Role C' };
  const allEmployments = { '': 'Please Select', 'staff': 'Staff', 'consultant': 'Consultant', 'job_applicant': 'Job Applicant' };
  const accessStatus = { '': 'Please Select', 'activate': 'Activate', 'deactivated': 'Deactivated' };

  useEffect(() => {
    const defaultLicenseId = Object.keys(currentUser.perLicenseSeats).reverse()[0];
    const defaultCourseSeries = currentUser.accessibleProducts.learn[defaultLicenseId]?.series?.[0];

    filtersLoaded(false);
    Promise.all([
      clearFilters(),
      receiveFilter('licenseFilter', defaultLicenseId),
      receiveFilter('coursesFilter', defaultCourseSeries),
      (Object.keys(groups).length === 0 ? fetchGroups() : null),
    ]).then(() => filtersLoaded(true));
  }, [fetchGroups, forFilters]);

  return (
    <div className="B2B-Admin-Filters-Container">
      <div className="Top-Filters Filters">
        {
          showFilters.includes('licenseFilter') && (
            <div className="Filter-Dropdown">
              <i className="fa fa-id-card Icon"></i>
              <select
                className="Select"
                value={filters.licenseFilter}
                onChange={e => {
                  receiveFilter('licenseFilter', e.currentTarget.value);
                  receiveFilter('coursesFilter', currentUser.accessibleProducts.learn[e.currentTarget.value]?.series?.[0]);
                }}
              >
                {
                  Object.values(currentUser.perLicenseSeats).reverse().map(license => (
                    <option key={`${license.id}-${license.name}`} value={license.id} children={license.access_code} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }
      </div>

      <div className="Mid-Filters Filters">
        {
          showFilters.includes('coursesFilter') && (
            <div className="Languages-Filter">
              {
                currentUser.accessibleProducts.learn[filters.licenseFilter]?.series?.map((acronym, idx) => (
                  <div
                    key={idx}
                    className={`Language ${filters.coursesFilter == acronym ? 'Selected' : ''}`}
                    onClick={() => receiveFilter('coursesFilter', acronym)}
                  >
                    {courseSeriesList[acronym]}
                  </div>
                ))
              }
            </div>
          )
        }
      </div>

      <div className="Bottom-Filters Filters">
        {
          showFilters.includes('timesFilter') && (
            <div className="Filter-Dropdown">
              <i className="fa fa-calendar Icon"></i>
              <select
                className="Select"
                value={filters.timesFilter || ''}
                onChange={e => receiveFilter('timesFilter', e.currentTarget.value)}
              >
                {
                  Object.entries(timeIntervalsList).map(([k, v]) => (
                    <option key={k} value={k} children={v} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }

        {
          showFilters.includes('examStatusesFilter') && (
            <div className="Filter-Dropdown">
              <ImageBox src="increasing-lines.svg" className="Image" />
              <select
                className="Select"
                value={filters.examStatusesFilter || ''}
                onChange={e => receiveFilter('examStatusesFilter', e.currentTarget.value)}
              >
                <option value="" children="Please Select" />
                {
                  Object.entries(examStatusesList).map(([groupName, options]) => (
                    <optgroup label={groupName}>
                      {
                        Object.entries(options).map(([key, val]) => (
                          <option key={key} value={key} children={val} />
                        ))
                      }
                    </optgroup>
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }

        {
          showFilters.includes('groupsFilter') && (
            <div className="Filter-Dropdown">
              <ImageBox src="groupIcon.svg" className="Image" />
              <select
                className="Select"
                value={filters.groupsFilter || ''}
                onChange={(e) => receiveFilter('groupsFilter', e.currentTarget.value)}
              >
                <option value="" children="Please Select" />
                {
                  Object.values(groups).map(group => (
                    <option key={`${group.id}-${group.name}`} value={group.name} children={group.name} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }

        {
          showFilters.includes('employmentStatusesFilter') && (
            <div className="Filter-Dropdown">
              <ImageBox src="employmentStatus.svg" className="Image" />
              <select
                className="Select"
                value={filters.employmentStatusesFilter || ''}
                onChange={(e) => receiveFilter('employmentStatusesFilter', e.currentTarget.value)}
              >
                {
                  Object.entries(allEmployments).map(([k, v]) => (
                    <option key={k} value={k} children={v} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }

        {
          showFilters.includes('facilitiesFilter') && (
            <div className="Filter-Dropdown">
              <ImageBox src="facilitiesIcon.svg" className="Image" />
              <select
                className="Select"
                value={filters.facilitiesFilter || ''}
                onChange={(e) => receiveFilter('facilitiesFilter', e.currentTarget.value)}
              >
                {
                  Object.entries(allFacilities).map(([k, v]) => (
                    <option key={k} value={k} children={v} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }

        {
          showFilters.includes('departmentsFilter') && (
            <div className="Filter-Dropdown">
              <ImageBox src="departmentIcon.svg" className="Image" />
              <select
                className="Select"
                value={filters.departmentsFilter || ''}
                onChange={(e) => receiveFilter('departmentsFilter', e.currentTarget.value)}
              >
                {
                  Object.entries(departmentsList).map(([k, v]) => (
                    <option key={k} value={k} children={v} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }

        {
          showFilters.includes('rolesFilter') && (
            <div className="Filter-Dropdown">
              <ImageBox src="roleIcon.svg" className="Image" />
              <select
                className="Select"
                value={filters.rolesFilter || ''}
                onChange={(e) => receiveFilter('rolesFilter', e.currentTarget.value)}
              >
                {
                  Object.entries(allRoles).map(([k, v]) => (
                    <option key={k} value={k} children={v} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }

        {
          showFilters.includes('accessStatusesFilter') && (
            <div className="Filter-Dropdown">
              <ImageBox src="on_off_switch.svg" className="Image" />
              <select
                className="Select"
                value={filters.accessStatusesFilter || ''}
                onChange={(e) => receiveFilter('accessStatusesFilter', e.currentTarget.value)}
              >
                {
                  Object.entries(accessStatus).map(([k, v]) => (
                    <option key={k} value={k} children={v} />
                  ))
                }
              </select>
              <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
