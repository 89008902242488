import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import CreateEditUserPreference from '../../../Forms/CreateEditUserPreference/CreateEditUserPreference';
import UserLevelSetting from '../../../Composed/UserLevelSetting/UserLevelSettingContainer';
import TextBox from '../../../Basic/TextBox/TextBox';

import './UserPreference.scss';

const UserPreference = ({ currentUser, profileCompletion }) => {
  const queryString = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (queryString.has('sec') && (queryString.get('sec') === 'preferences')) {
      const scrollElement = document.getElementById('UserPreferences');
      window.scrollTo({ top: (scrollElement.offsetTop - 80), behavior: 'smooth' });
    }
  }, [queryString]);

  return (
    <div className="UserPreference" id="UserPreferences">
      <div className="DashboardFormBox">
        <div className="DashboardFormBox-Title_container Tour-Step-Profile-Preference-Title">
          <div className="DashboardFormBox-Title">
            <TextBox
              className="Title"
              text="Preferences"
            />
          </div>
          <UserLevelSetting
            settingName="notifications_blocked"
            onConfig={{
              btnClass: 'Green-Button',
              btnTxtClass: 'uppercase',
              text: 'Activate notifications',
            }}
            offConfig= {{
              btnClass: 'Red-Button',
              btnTxtClass: 'uppercase',
              text: 'Block all notifications',
            }}
          />
        </div>

        <div className="DashboardFormBox-Content_container">
          <div className="Email-Notifications">
            <TextBox
              className="Sub-Title"
              text="Notifications"
            />

            {
              profileCompletion.settings.notifications_blocked ? (
                <div className="Notice">
                  <i className="fa fa-info-circle Icon" />
                  <span className="Text">
                    You have blocked all notifications, except for those deemed most important. Activate notifications to customize individual notification preferences.
                  </span>
                </div>
              ) : (
                currentUser.userPreferences.map((userPreference, idx) => (
                  <CreateEditUserPreference
                    key={`preference-${idx}`}
                    userPreference={userPreference}
                  />
                ))
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

UserPreference.propTypes = {
  currentUser: PropTypes.object.isRequired,
  profileCompletion: PropTypes.object.isRequired,
}

export default UserPreference;
