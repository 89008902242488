import React, { useState, useEffect, useReducer } from 'react';
import './styles.scss';
import { sortBy } from 'lodash';
import { fetchInstitutions } from '../../../Util/InstitutionApiUtil';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

const CREATE_LICENSE = 'CREATE LICENSE';
const HEADING_STEP_ONE = 'STEP 1: CREATE AN INSTITUTION LICENSE';
const HEADING_STEP_TWO = 'B2B INDIVIDUAL PAYS';
const HEADING_STEP_THREE = 'B2B BUSINESS PAYS';
const SUBHEADING_STEP_ONE = 'Enter the details of an institution license below';
const SUBHEADING_STEP_TWO = 'Enter a Stripe coupon code if you want to give a discount to the users under this license';
const SUBHEADING_STEP_THREE = "Select the courses that you want to include in the license. User this form only for 'business' type licenses";

const actions = {
    EDIT_INSTITUTION_ID: 'EDIT_INSTITUTION_ID',
    EDIT_INSTITUTION_LIST: 'EDIT_INSTITUTION_LIST',
    EDIT_ACCESS_CODE: 'EDIT_ACCESS_CODE',
    EDIT_EXPIRATION_DATE: 'EDIT_EXPIRATION_DATE',
    EDIT_LICENSE_TYPE: 'EDIT_LICENSE_TYPE',
    EDIT_NUM_SEATS: 'EDIT_NUM_SEATS',
    EDIT_SUBSCRIPTION_DATE: 'EDIT_SUBSCRIPTION_DATE',
    EDIT_COUPON: 'EDIT_COUPON',
    EDIT_COURSE_PACKAGES_LIST: 'EDIT_COURSE_PACKAGES_LIST',
    EDIT_COURSES_LIST: 'EDIT_COURSES_LIST',
    EDIT_COURSE_PACKAGES_LICENSES: 'EDIT_COURSE_PACKAGES_LICENSES',
    EDIT_COURSE_LICENSES: 'EDIT_COURSE_LICENSES',
    RESET_LICENSE_DOMAINS: 'RESET_LICENSE_DOMAINS',
    ADD_LICENSE_DOMAIN: 'ADD_LICENSE_DOMAIN',
    EDIT_LICENSE_DOMAIN: 'EDIT_LICENSE_DOMAIN',
};

const state = {
    INSTITUTION_ID: 'institution_id',
    INSTITUTION_LIST: 'institution_list',
    ACCESS_CODE: 'access_code',
    EXPIRATION_DATE: 'expiration_date',
    LICENSE_TYPE: 'license_type',
    NUM_SEATS: 'num_seats',
    SUBSCRIPTION_DATE: 'subscription_date',
    COUPON: 'coupon',
    COURSE_PACKAGES_LIST: 'course_packages_list',
    COURSES_LIST: 'courses_list',
    COURSE_PACKAGES_LICENSES: 'course_packages_licenses',
    COURSE_LICENSES: 'course_licenses',
};

const initialState = {
    access_code: '',
    institution_id: 1,
    institution_list: [],
    expiration_date: '',
    license_type: '',
    num_seats: 0,
    subscription_date: '',
    course_packages_list: [],
    courses_list: [],
    course_packages_licenses: [],
    course_licenses: [],
    license_domains_attributes: [],
};

const formEntries = [
    ['ACCESS CODE', 'text', actions.EDIT_ACCESS_CODE, state.ACCESS_CODE],
    ['NUM SEATS', 'number', actions.EDIT_NUM_SEATS, state.NUM_SEATS],
    ['LICENSE START DATE', 'date', actions.EDIT_SUBSCRIPTION_DATE, state.SUBSCRIPTION_DATE],
    ['LICENSE END DATE', 'date', actions.EDIT_EXPIRATION_DATE, state.EXPIRATION_DATE],
];


/**
 * TextInput Component
 * @param {*} param0 
 */
const TextInput = ({ label, type, callback, error, value, size, disabled, placeholder }) => (
    <>
        <div className="createLicenseContainer__label-row">
            <p className="createLicenseContainer__label">{label}</p>
            <p className="createLicenseContainer__label-error">{error ? error[0] : null}</p>
        </div>
        <input
            disabled={disabled}
            className={error ? `createLicenseContainer__textinput-error__${size}` : `createLicenseContainer__textinput__${size}`}
            type={type}
            onChange={callback}
            value={value}
            placeholder={placeholder}
        />
    </>
);


/**
 * CheckBox Component
 * @param {*} param0 
 */
const Checkbox = ({ text, callback, value }) => (
    <label className="createLicenseContainer__checkbox">
        {text}
        <input
            type="checkbox"
            onClick={callback}
            checked={value}
        />
        <span className="checkmark" />
    </label>
);


/**
 * CreateLicense component Reducer
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state, action) => {
    switch (action.type) {
        case actions.EDIT_ACCESS_CODE:
            return { ...state, access_code: action.payload.replace(/\s+/g, '') };
        case actions.EDIT_EXPIRATION_DATE:
            return { ...state, expiration_date: action.payload };
        case actions.EDIT_LICENSE_TYPE:
            return { ...state, license_type: action.payload };
        case actions.EDIT_NUM_SEATS:
            return { ...state, num_seats: action.payload };
        case actions.EDIT_SUBSCRIPTION_DATE:
            return { ...state, subscription_date: action.payload };
        case actions.EDIT_COUPON:
            return { ...state, coupon: action.payload }
        case actions.EDIT_INSTITUTION_LIST: {
            return { ...state, institution_list: [...Object.values(action.payload)] };
        }
        case actions.EDIT_COURSE_PACKAGES_LIST: {
            return { ...state, course_packages_list: [...Object.values(action.payload?.coursePackages)] };
        }
        case actions.EDIT_COURSES_LIST: {
            return { ...state, courses_list: [...Object.values(action.payload?.courses)] };
        }
        case actions.EDIT_INSTITUTION_ID:
            return { ...state, institution_id: action.payload };
        case actions.EDIT_COURSE_PACKAGES_LICENSES:
            return { ...state, course_packages_licenses: action.payload };
        case actions.EDIT_COURSE_LICENSES:
            return { ...state, course_licenses: action.payload };
        case actions.RESET_LICENSE_DOMAINS:
            return { ...state, license_domains_attributes: [] };
        case actions.ADD_LICENSE_DOMAIN:
            const addLicenseDomains = state.license_domains_attributes;
            addLicenseDomains[action.index] = {};
            return { ...state, license_domains_attributes: addLicenseDomains };
        case actions.EDIT_LICENSE_DOMAIN:
            const editLicenseDomains = state.license_domains_attributes;
            editLicenseDomains[action.index]['domain'] = action.payload;
            return { ...state, license_domains_attributes: editLicenseDomains };
        case 'reset':
            return initialState;
        default:
            return state;
    }
};

/**
 * CreateLicense Component
 * @param {*} props
 */
const CreateLicense = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        dispatch({ type: actions.RESET_LICENSE_DOMAINS })
    }, []);

    /**
     * Accepts a function that contains imperative, possibly effectful code.
     */
    useEffect(() => {
        fetchInstitutions().then((res) => {
            dispatch({
                type: actions.EDIT_INSTITUTION_LIST,
                payload: res,
            });
        });
    }, [state.institution_list.length]);


    /**
     * Accepts a function that contains imperative, possibly effectful code.
     */
    useEffect(() => {
        props.fetchCoursePackages().then((res) => {
            dispatch({
                type: actions.EDIT_COURSE_PACKAGES_LIST,
                payload: res.payload,
            });
            dispatch({
                type: actions.EDIT_COURSES_LIST,
                payload: res.payload,
            });
        });
    }, [state.course_packages_list.length]);


    /**
     * Submit action for creating a License
     * @param {*} e 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            institution_list,
            course_packages_list,
            courses_list,
            ...rest
        } = state;
        setProcessing(true);
        props.createLicense(rest).then(() => {
            setProcessing(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
        // dispatch({ type: 'reset' });
    };


    /**
     * Util to handle coursePackagesState and its complex logic.
     * If coursePackage exists on the array, then remove it, else append it.
     * @param {*} entryId
     */
    const handleCoursePackagesState = entryId => {
        const addOrRemove = (entry, array) => {
            const arrayOfObjects = array;
            for (let i = 0; i < arrayOfObjects.length; i += 1) {
                if (arrayOfObjects[i].id === entry) {
                    arrayOfObjects.splice(i, 1);
                    return arrayOfObjects;
                }
            }
            arrayOfObjects.push({ id: entry, coupon: '' });
            return arrayOfObjects;
        };
        dispatch({
            type: actions.EDIT_COURSE_PACKAGES_LICENSES,
            payload: addOrRemove(entryId, state.course_packages_licenses),
        });
    };


    /**
     * Util to handle updating prices of a coursePackage.
     * @param {*} event 
     * @param {*} entryId 
     */
    const handleCoursePackagesPriceState = (event, entryId) => {
        state.course_packages_licenses.forEach((entry, index, array) => {
            if (entry.id === entryId) {
                array[index].price = event.target.value;
                dispatch({
                    type: actions.EDIT_COURSE_PACKAGES_LICENSES,
                    payload: array,
                });
            }
        });
    };

    /**
     * Util to handle updating coupons of a coursePackage.
     * @param {*} event
     * @param {*} entryId
     */
    const handleCoursePackagesCouponState = (event, entryId) => {
        state.course_packages_licenses.forEach((entry, index, array) => {
            if (entry.id === entryId) {
                array[index].coupon = event.target.value;
                dispatch({
                    type: actions.EDIT_COURSE_PACKAGES_LICENSES,
                    payload: array,
                });
            }
        });
    };

    /**
     * Util to handle updating prices of a coursePackage.
     * @param {*} event 
     * @param {*} entryId 
     */
    const handleCoursePackagesSubscriptionLengthState = (event, entryId) => {
        state.course_packages_licenses.forEach((entry, index, array) => {
            if (entry.id === entryId) {
                array[index].length = event.target.value;
                dispatch({
                    type: actions.EDIT_COURSE_PACKAGES_LICENSES,
                    payload: array,
                });
            }
        });
    };


    /**
     * Util to handle coursePackagesState and its complex logic.
     * If coursePackage exists on the array, then remove it, else append it.
     * @param {*} entryId
     */
    const handleCourseLicensesState = entryId => {
        const addOrRemove = (entry, array) => {
            const courses = array;
            for (let i = 0; i < courses.length; i += 1) {
                if (courses[i] === entry) {
                    courses.splice(i, 1);
                    return courses;
                }
            }
            courses.push(entryId);
            return courses;
        };
        dispatch({
            type: actions.EDIT_COURSE_LICENSES,
            payload: addOrRemove(entryId, state.course_licenses),
        });
    };

    return (
        <div className="AuthPage">
            {processing && <MirrorLoader message="Processing..."/>}
            <div className="createLicenseContainer">
                {
                    props.flash.message
                    && (
                        <div className="createLicenseContainer__flash">
                            {props.flash.message}
                        </div>
                    )
                }
                <p className="createLicenseContainer__header">{HEADING_STEP_ONE}</p>
                <p className="createLicenseContainer__subheader">{SUBHEADING_STEP_ONE}</p>
                {
                    <>
                        <div className="createLicenseContainer__label-row">
                            <p className="createLicenseContainer__label">LICENSE TYPE</p>
                        </div>
                        <select
                            value={state.license_type}
                            className="createLicenseContainer__select"
                            onChange={e => dispatch({ type: actions.EDIT_LICENSE_TYPE, payload: e.target.value })}
                        >
                            <option value="" disabled>--SELECT--</option>
                            <option value="business">business</option>
                            <option value="individual">individual</option>
                        </select>
                    </>
                }
                {
                    formEntries.map(entry => (
                        <React.Fragment key={entry[0]}>
                            <TextInput
                                callback={(e) => dispatch({ type: entry[2], payload: e.target.value })}
                                error={props.errors[entry[3]]}
                                label={entry[0]}
                                type={entry[1]}
                                value={state[entry[3]]}
                                size={'large'}
                                placeholder={entry[4]}
                            />
                        </React.Fragment>
                    ))
                }
                {
                    <>
                        <div className="createLicenseContainer__label-row">
                            <p className="createLicenseContainer__label">INSTITUTION</p>
                        </div>
                        <select
                            value={state.institution_id}
                            className="createLicenseContainer__select"
                            onChange={e => dispatch({ type: actions.EDIT_INSTITUTION_ID, payload: +e.target.value })}
                        >
                            {
                                sortBy(state.institution_list, i => i?.name?.toLowerCase()).map(entry => (
                                    <option
                                        key={entry.id}
                                        value={entry.id}
                                    >
                                        {entry.name}
                                    </option>
                                ))
                            }
                        </select>
                    </>
                }
            </div>

            <div className="createLicenseContainer License-Domains-Container">
                <p className="createLicenseContainer__header">Email Domains</p>
                <p className="createLicenseContainer__subheader">Enter email domains, to grant licensed resources to users with email addresses from these domains.</p>

                <button
                    type="button"
                    className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save Add-Btn"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch({ type: actions.ADD_LICENSE_DOMAIN, index: state.license_domains_attributes.length });
                    }}
                >
                    Add Domain
                </button>

                {
                    props.errors['license_domains.domain'] &&
                    props.errors['license_domains.domain'].map((err, idx) => (
                        <div key={`domain-error-${idx}`} className="Error-Container">{`The domain ${err}`}</div>
                    ))
                }

                {
                    state.license_domains_attributes.map((license_domain, idx) => (
                        <input
                            type="text"
                            key={`license-domain-${idx}`}
                            className="createLicenseContainer__textinput__large Input"
                            value={license_domain.domain || ''}
                            placeholder="@example.com"
                            onChange={(e) => dispatch({ type: actions.EDIT_LICENSE_DOMAIN, index: idx, payload: e.target.value })}
                        />
                    ))
                }
            </div>

            <div className="createLicenseContainer Individual-Pay-Container">
                <p className="createLicenseContainer__header">{HEADING_STEP_TWO}</p>
                <p className="createLicenseContainer__subheader">{SUBHEADING_STEP_TWO}</p>
                {

                    state.course_packages_list?.map(entry => (
                        <div
                            key={entry.name}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'spacebetween',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                text={entry.name}
                                callback={() => handleCoursePackagesState(entry.id)}
                            />
                            &nbsp;
                            <div>
                                {
                                    state.course_packages_licenses.filter(e => e.id === entry.id).length > 0
                                    && <TextInput
                                        callback={(e) => handleCoursePackagesCouponState(e, entry.id)}
                                        size={'large'}
                                        type={'text'}
                                        placeholder={'Enter Coupon'}
                                    />
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="createLicenseContainer Business-Pay-Container">
                <p className="createLicenseContainer__header">{HEADING_STEP_THREE}</p>
                <p className="createLicenseContainer__subheader">{SUBHEADING_STEP_THREE}</p>
                {
                    state.courses_list?.map(entry => (
                        <div
                            key={entry.name}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                text={entry.name}
                                callback={() => handleCourseLicensesState(entry.id)}
                            />
                        </div>
                    ))
                }
            </div>
            <button
                type="button"
                className="createLicenseContainer__button"
                disabled={false}
                onClick={(e) => handleSubmit(e)}
            >
                {CREATE_LICENSE}
            </button>
        </div>
    );
};

export default CreateLicense;
