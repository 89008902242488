import './MatchingBox.css';

import React, { Component } from 'react';
import { withDragDropContext } from '../../context';
import { connect } from 'react-redux';

import { COLORS } from '../../../Style/Style';
import { shuffle } from "../../../Util/Helper/GenericUtil";
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import QuestionBox from '../../Quiz/QuestionBox/QuestionBox';
import DragElement from '../DragAndDropBox/DragElement';
import DragPreview from '../DragAndDropBox/DragPreview';
import DropArea from '../DragAndDropBox/DropArea';

const mapStateToProps = (state) => ({
  quizStates: state.statistics.quizStates
});

class MatchingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lengthyDrag: false,
    };
  }

  componentDidMount() {
    const { questions, quizProgress, quizStates, section, recordQuizState } = this.props;

    if (!quizStates[section.id]) {
      let alreadyGivenAnswers = [];
      Object.values(quizProgress?.[section.id] || {}).map(givenAnswers => (
        Object.values(givenAnswers || {}).map(givenAnswer => alreadyGivenAnswers.push(givenAnswer.text))
      ));
      recordQuizState({
        [section.id]: {
          options: shuffle(questions.slice(0)).map(
            (question, idx) => {
              const answer = question.answers[0].text;
              const occupiedIndex = alreadyGivenAnswers.indexOf(answer);
              if (occupiedIndex >= 0) {
                alreadyGivenAnswers.splice(occupiedIndex, 1);
                return null;
              }
              return {
                answer,
                origin: {
                  section: 'options',
                  index: idx
                }
              };
            }
          ),
          questions: questions.map(
            (question, idx) => {
              if (!quizProgress?.[section.id]?.[question.id]?.[0]?.text) {
                return null;
              }
              return {
                answer: quizProgress[section.id][question.id][0].text,
                origin: {
                  section: 'questions',
                  index: idx
                }
              }
            }
          ),
        }
      })
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    const { questions, quizProgress, quizStates, section, recordQuizState } = this.props;

    if (!quizStates[section.id]) {
      let alreadyGivenAnswers = [];
      Object.values(quizProgress?.[section.id] || {}).map(givenAnswers => (
        Object.values(givenAnswers || {}).map(givenAnswer => alreadyGivenAnswers.push(givenAnswer.text))
      ));
      recordQuizState({
        [section.id]: {
          options: shuffle(questions.slice(0)).map(
            (question, idx) => {
              const answer = question.answers[0].text;
              const occupiedIndex = alreadyGivenAnswers.indexOf(answer);
              if (occupiedIndex >= 0) {
                alreadyGivenAnswers.splice(occupiedIndex, 1);
                return null;
              }
              return {
                answer,
                origin: {
                  section: 'options',
                  index: idx
                }
              };
            }
          ),
          questions: questions.map(
            (question, idx) => {
              if (!quizProgress?.[section.id]?.[question.id]?.[0]?.text) {
                return null;
              }
              return {
                answer: quizProgress[section.id][question.id][0].text,
                origin: {
                  section: 'questions',
                  index: idx
                }
              }
            }
          ),
        }
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const elementProperties = document.getElementsByClassName('MatchingBox')?.[0]?.getBoundingClientRect();

    if (elementProperties) {
      this.setState({ lengthyDrag: (elementProperties.top - 65 <= 0) && (elementProperties.bottom - 200 >= 0) });
    }
  }

  getAvailableIndex(questions, opt) {
    return this.props.questions.reduce((targetIndex, question, currentIndex) => {
      if (question.answers[0].text === opt.answer && !questions[currentIndex]) {
        targetIndex = currentIndex
      }

      return targetIndex
    }, 0)
  }

  fillAnswers() {
    const { quizStates, section } = this.props;
    const { options, questions }  = quizStates[section.id];

    questions.map((opt, i) => {
      if (opt && (this.props.questions[i].answers[0].text !== opt.answer)) {
        opt['destination'] = {
          section: 'options',
          index:   options.indexOf(null)
        };
        this.moveOption(opt);
      }
    });

    options.map((opt, i) => {
      if (opt) {
        opt['destination'] = {
          section: 'questions',
          index:   this.getAvailableIndex(questions, opt),
        };
        this.moveOption(opt);
      }
    });
  }

  moveOption = (answerData) => {
    const { section, lessonId, recordQuizAnswer } = this.props;

    gaEvent('matching_box_move_option', { section_id: section.id });
    if(answerData.destination.section === "options") {
      if (answerData.origin.section === "options") {
        const { quizStates } = this.props;
        const { options, questions } = quizStates[section.id];
        options[answerData.origin.index] = null;
        answerData.origin = answerData.destination;
        delete answerData.destination;
        options[answerData.origin.index] = answerData;

        this.props.recordQuizState({
          [section.id]: {
            options,
            questions,
          }
        })
      } else {
        const answer = {
          [lessonId]: {
            [section.id]: {
              [this.props.questions[answerData.origin.index].id]: {
                0: {
                  text: "",
                  is_correct: false,
                }
              }
            }
          }
        };

        recordQuizAnswer(answer);

        const { quizStates } = this.props;
        const { options, questions } = quizStates[section.id];
        questions[answerData.origin.index] = null;
        answerData.origin = answerData.destination;
        delete answerData.destination;
        options[answerData.origin.index] = answerData;

        this.props.recordQuizState({
          [section.id]: {
            options,
            questions,
          }
        })
      }
    } else {
      if (answerData.origin.section === "options") {
        const answer = {
          [lessonId]: {
            [section.id]: {
              [this.props.questions[answerData.destination.index].id]: {
                0: {
                  text: answerData.answer,
                  is_correct: answerData.answer === this.props.questions[answerData.destination.index].answers[0].text,
                }
              }
            }
          }
        };

        recordQuizAnswer(answer);

        const { quizStates } = this.props;
        const { options, questions } = quizStates[section.id];
        options[answerData.origin.index] = null;
        answerData.origin = answerData.destination;
        delete answerData.destination;
        questions[answerData.origin.index] = answerData;

        this.props.recordQuizState({
          [section.id]: {
            options,
            questions,
          }
        })
      } else {
        const answer = {
          [lessonId]: {
            [section.id]: {
              [this.props.questions[answerData.destination.index].id]: {
                0: {
                  text: answerData.answer,
                  is_correct: answerData.answer ===
                      this.props.questions[answerData.destination.index].answers[0].text
                }
              },
              [this.props.questions[answerData.origin.index].id]: {
                0: {
                  text: "",
                  is_correct: false,
                }
              }
            }
          }
        };

        recordQuizAnswer(answer);

        const { quizStates } = this.props;
        const { options, questions } = quizStates[section.id];
        questions[answerData.origin.index] = null;
        answerData.origin = answerData.destination;
        delete answerData.destination;
        questions[answerData.origin.index] = answerData;

        this.props.recordQuizState({
          [section.id]: {
            options,
            questions,
          }
        })
      }
    }
  }

  pinUnansweredRender = (question) => {
    const { pinUnanswered, quizProgress, section } = this.props;

    return (
      pinUnanswered &&
      !quizProgress?.[section.id]?.[question.id]?.[0] &&
      (
        <div className="Pin-Unanswered">
          <ImageBox class="Pin-Unanswered-Img" src="pin-unanswered.svg" />
        </div>
      )
    )
  }

  renderQuestions = () => {
    const { questions, section, quizProgress } = this.props;
    const questionsList = questions.map((question, idx) => {
      if (!this.props.quizStates[this.props.section.id].questions[idx]) {
        return (
          <DropArea
            className="MatchingBox-Questions-QuestionBox"
            key={idx}
            destination={{
              section: "questions",
              index: idx
            }}
          >
            { this.pinUnansweredRender(question) }
            <div style={{ display: "flex", alignItems: "center" }}>
              {
                !this.props.isComplianceCourse && (
                  <div className="FillInTheBlankBox-QuestionBox-Position">
                    <TextBox text={question.position} />
                  </div>
                )
              }

              <QuestionBox question={ question }>
                <TextBox
                  text={ question.text }
                  tag='h5'
                  style={{
                    color: COLORS.Blue
                  }}/>
              </QuestionBox>
            </div>

            <div 
              className="DragAndDropBox-Draggable__container" key={idx + 1}
            >
              
              {
                this.props.checkAnswers &&
                <i class="fas fa-times icon-wrong"></i>
              }

              <div
                className={
                  this.props.checkAnswers ?
                    "DropArea-Questions DragAndDropBox-wrong"
                    :
                    "DropArea-Questions"
                }
              />
            </div>
          </DropArea>
        );
      }

      let isCorrect = quizProgress?.[section.id]?.[question.id]?.[0]?.is_correct;

      return (
        <div
          className="MatchingBox-Questions-QuestionBox"
          key={ idx }>
          <div style={{ display: "flex", alignItems: "center" }}>
            {
              !this.props.isComplianceCourse && (
                <div className="FillInTheBlankBox-QuestionBox-Position">
                  <TextBox text={question.position} />
                </div>
              )
            }

            <QuestionBox question={question}>
              <TextBox
                text={question.text}
                tag='h5'
                style={{
                  color: COLORS.Blue
                }} />
            </QuestionBox>
          </div>


          <div
            className="DragAndDropBox-Draggable__container"
          >

            {
              this.props.checkAnswers && quizProgress?.[section.id]?.[question.id]?.[0] &&
              (
                quizProgress[section.id][question.id][0].is_correct ?
                  <i class="fas fa-check icon-correct"></i>
                  :
                  <i class="fas fa-times icon-wrong"></i>
              )
            }

            <DragElement
              className={
                this.props.checkAnswers ?
                  (
                    isCorrect ?
                      "DragElement DragAndDropBox-correct"
                      :
                      "DragElement DragAndDropBox-wrong"
                  )
                  :
                  "DragElement"
              }
              answerData={
                this.props.quizStates[this.props.section.id].questions[idx]
              }
              handleDrop={ (answerData) => this.moveOption(answerData) }
            />
          </div>
        </div>
      );
    });

    return questionsList;
  }

  renderOptions() {
    const optionsList = this.props.quizStates[this.props.section.id].options.map((option, idx) => {
      if (!option) {
        return (
          <DropArea
            className="DropArea-Options"
            destination={{
              section: 'options',
              index: idx,
            }}
            key={idx}/>
        );
      }

      return (
        <DragElement
          answerData={
            option
          }
          handleDrop={ (answerData) => this.moveOption(answerData) }
          key={ idx }/>
      );
    });

    return optionsList;
  }

  render () {
    if (!this.props.quizStates[this.props.section.id]) {
      return <div></div>
    }

    if (this.props.revealAnswers) {
      this.fillAnswers();
    }

    return (
      <div className={`MatchingBox ${this.state.lengthyDrag ? 'Lengthy-Drag' : ''}`}>
        <div
          className="MatchingBox-OptionsArea DragAndDropBox-OptionsArea">
          { this.renderOptions() }
        </div>

        <div
          className="MatchingBox-Questions DragAndDropBox-Questions">
          { this.renderQuestions() }
        </div>

        <DragPreview />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(withDragDropContext(MatchingBox));
