import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import RadioBox from '../../Basic/RadioBox/RadioBox';
import Loader from '../../Composed/Loader/Loader';
import Confirm from '../../Composed/Confirm/Confirm';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import DashboardFormBoxNoEdit from '../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';

import { AdminUserTypesList } from '../../../Constants/UserConstants';

import './ModifyUser.scss';

const ModifyUser = ({
  institutions, clearInstitutions, fetchInstitutions,
  checkExistingUser, updateUserType, confirmUser, archiveUser, overidePassword,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [email, setEmail] = useState('');
  const [exists, setExists] = useState(false);
  const [user, setUser] = useState(null);
  const [userTypeAttrs, setUserTypeAttrs] = useState({ user_type: '', institution_id: '' });
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    clearInstitutions();
    fetchInstitutions().then(() => setLoading(false));
  }, [clearInstitutions, fetchInstitutions]);

  const handleCheckExistingUser = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    if (email.trim().length === 0) {
      setErrors({ '': 'Please enter email address to check user\'s existence.' });
      return null;
    }

    setProcessing(true);
    setUser(null);
    setExists(false);
    checkExistingUser({ email }).then(res => {
      setUser(res.user);
      setExists(res.exists);
      setProcessing(false);

      if (res.exists) {
        setFlash({ message: 'A user with this email exists in the system.' });
      } else {
        setErrors({ '': 'A user with this email does not exists in the system.' });
      }
    });
  };

  const handleUpdateType = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    if (!Object.keys(AdminUserTypesList).includes(userTypeAttrs.user_type)) {
      setErrors({ '': 'Please select a user type!' });
      return null;
    }

    if (userTypeAttrs.institution_id.length === 0) {
      setErrors({ '': 'Please select an institution!' });
      return null;
    }

    setProcessing(true);
    updateUserType({ id: user.id, ...userTypeAttrs }).then(res => {
      handleResponse(res)
    });
  }

  const handleConfirm = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    setProcessing(true);
    confirmUser(user.id).then(res => {
      handleResponse(res)
    });
  };

  const handleArchiveUser = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    setProcessing(true);
    archiveUser(user.id).then(res => handleResponse(res));
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*?~]{8,}$/.test(newPassword))) {
      setErrors({ '': 'Password should consist of 8 or more characters. Also, it should have a lower case letter, a upper case letter, a digit, and a special character (e.g. !@#$%^&*?~).' })
      return null;
    }

    setProcessing(true);
    overidePassword({ id: user.id, password: newPassword }).then(res => {
      handleResponse(res)
    });
  };

  const handleResponse = (res) => {
    if (res.errors) {
      setErrors({ '': res.errors.message });
    } else {
      setFlash({ message: res.message });
    }
    setProcessing(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleClear = (e) => {
    e.preventDefault();

    setErrors({});
    setFlash({});
    setEmail('');
    setExists(false);
    setUser(null);
    setUserTypeAttrs({ user_type: '', institution_id: '' });
    setNewPassword('');
  };

  const handleUserTypeChange = (e) => {
    if (e.currentTarget.value === 'TE.Admin') {
      setUserTypeAttrs({ ...userTypeAttrs, user_type: e.currentTarget.value, institution_id: '1' });
    } else if (e.currentTarget.value === 'B2B.Admin') {
      setUserTypeAttrs({ ...userTypeAttrs, user_type: e.currentTarget.value, institution_id: '' });
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Modify-User-Container createUserContainer CreateUser">
      <ErrorFlashBox errors={errors} flash={flash} />

      {processing && <MirrorLoader message="Processing..." />}

      <DashboardFormBoxNoEdit title="MODIFY USER">
        <div className="DashboardFormBox-Line Manage-User-Access fdc">
          <div className="Verify-Head">
            <div className="Full-Width">
              <div className="DashboardFormBox-Label left">
                User Email
              </div>
              <button
                type="button"
                className="CreateUser-Update-Button Verify-Btn"
                onClick={(e) => handleCheckExistingUser(e)}
              >
                Verify
              </button>
            </div>
          </div>
          <div className="Verify-Body">
            <input
              type="text"
              value={email}
              disabled={exists}
              className={`${exists && 'ValidEmail'} Full-Width`}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            {exists && <i className="fas fa-check Check-Icon" />}
          </div>
        </div>
      </DashboardFormBoxNoEdit>

      {
        exists &&
        <DashboardFormBoxNoEdit title="Update User Type">
          <div className="User-Types-Container">
            <div className="DashboardFormBox-Line">
              <div className="formWidth">
                <div className="DashboardFormBox-Label left">
                  <strong className="mr-10">Current User Type:</strong>
                  <span>{user.user_type}</span>
                </div>
              </div>
            </div>
            <div className="RadioBox-Holder">
              {
                Object.entries(AdminUserTypesList)?.map(([k, v], idx) => (
                  <RadioBox
                    key={`admin-type-${idx}`}
                    name="type"
                    value={k}
                    text={v}
                    checked={userTypeAttrs.user_type === k}
                    className="RadioBox"
                    onChange={(e) => handleUserTypeChange(e)}
                  />
                ))
              }
            </div>
            {
              userTypeAttrs.user_type === 'TE.Admin' &&
              <div className="Selected-Type">
                <div className="Note">
                  As a TE.Admin, a user can access all site-wide content, such as courses, course packages, institutions, licenses, comments, etc. Event it can make other users a TE.Admin as well. So, please be sure, if you really want this user to be TE.Admin.
                </div>
              </div>
            }
            {
              userTypeAttrs.user_type === 'B2B.Admin' &&
              <div className="Selected-Type">
                <div className="Note">
                  As a B2B.Admin, a user can access all institution-wide features, such as inviting users, accessing different dashboards, etc. So, please be sure, if you really want this user to be B2B.Admin of the selected institution.
                </div>
                <div className="Select-List-Holder">
                  <select
                    value={userTypeAttrs.institution_id}
                    className="createLicenseContainer__select"
                    onChange={e => setUserTypeAttrs({ ...userTypeAttrs, institution_id: e.currentTarget.value })}
                  >
                    <option value="" children="Please select an institution" />
                    {
                      sortBy(Object.values(institutions), i => i?.name?.toLowerCase()).map((institution, idx) => (
                        <option
                          key={`institution-${institution.id}-${idx}`}
                          value={institution.id}
                          children={institution.name}
                        />
                      ))
                    }
                  </select>
                </div>
              </div>
            }
            <div className="Button-Container">
              <ButtonBox
                text="Update User Type"
                className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save"
                onClick={e => handleUpdateType(e)}
              />
              <ButtonBox
                text="Clear"
                className="DashboardFormBox-CancelBtn DashboardFormBox-Button_cancel"
                onClick={(e) => handleClear(e)}
              />
            </div>
          </div>
        </DashboardFormBoxNoEdit>
      }

      {
        exists &&
        <DashboardFormBoxNoEdit title="CONFIRM USER">
          <div className="Button-Container">
            <ButtonBox
              text="Confirm User"
              className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save"
              onClick={(e) => handleConfirm(e)}
            />
            <ButtonBox
              text="Clear"
              className="DashboardFormBox-CancelBtn DashboardFormBox-Button_cancel"
              onClick={(e) => handleClear(e)}
            />
          </div>
        </DashboardFormBoxNoEdit>
      }

      {
        exists &&
        <DashboardFormBoxNoEdit title="ARCHIVE USER">
          <div className="Button-Container">
            <Confirm
              text="Are you sure you want to archive this user?"
              confirm={(e) => handleArchiveUser(e)}
            >
              {
                open => (
                  <ButtonBox
                    className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save"
                    text="Archive User"
                    onClick={open}
                  />
                )
              }
            </Confirm>

            <ButtonBox
              text="Clear"
              className="DashboardFormBox-CancelBtn DashboardFormBox-Button_cancel"
              onClick={(e) => handleClear(e)}
            />
          </div>
        </DashboardFormBoxNoEdit>
      }

      {
        exists &&
        <DashboardFormBoxNoEdit title="CHANGE PASSWORD">
          <div className="DashboardFormBox-Line">
            <div className="formWidth">
              <div className="DashboardFormBox-Label left">
                User Password
              </div>
            </div>
            <div className="Input-Container">
              <input
                type="password"
                className="formWidth"
                value={newPassword}
                onChange={(e) => setNewPassword(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="Button-Container">
            <ButtonBox
              text="Change Password"
              className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save"
              onClick={(e) => handlePasswordChange(e)}
            />
            <ButtonBox
              text="Clear"
              className="DashboardFormBox-CancelBtn DashboardFormBox-Button_cancel"
              onClick={(e) => handleClear(e)}
            />
          </div>
        </DashboardFormBoxNoEdit>
      }
    </div>
  );
};

ModifyUser.propTypes = {
  institutions: PropTypes.object,
  clearInstitutions: PropTypes.func.isRequired,
  fetchInstitutions: PropTypes.func.isRequired,
  checkExistingUser: PropTypes.func.isRequired,
  updateUserType: PropTypes.func.isRequired,
  confirmUser: PropTypes.func.isRequired,
  archiveUser: PropTypes.func.isRequired,
  overidePassword: PropTypes.func.isRequired,
};

export default ModifyUser;
