import axios from './AxiosUtil';

export const createPronunciationAssessment = pronunciationAssessment => (
  axios({
    method: 'POST',
    url: '/api/pronunciation_assessments',
    processData: false,
    contentType: false,
    dataType: 'json',
    data: pronunciationAssessment,
  }).then(
    res => res.data,
  )
)
