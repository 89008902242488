import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import { isB2BBusiness } from '../../../Util/Helper/UserWorkplaceUtil';

import './CertificateLocator.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  currentWorkplace: state.navigation.currentWorkplace,
});

const mapDispatchToProps = (dispatch) => ({
});

const CertificateLocator = ({ currentUser, currentWorkplace, course, lessons, quizResults, className = '' }) => {
  const [showLocator, setShowLocator] = useState(false);
  const [showLocatorPopup, setShowLocatorPopup] = useState(false);

  useEffect(() => {
    if (course) {
      let finalLessonId = course.lessons[course.lessons.length - 1];
      let finalLesson = lessons[finalLessonId];

      if (quizResults?.[finalLesson?.quiz_id]) {
        setShowLocator(true);
      }
    } else {
      setShowLocator(true);
    }
  }, [lessons, quizResults]);

  if (!showLocator) {
    return '';
  }

  return (
    <div className={`Certificate-Locator-Container ${className}`}>
      <div className="Opener">
        <ButtonBox
          className="Button"
          text="Where's my certificate?"
          src="certificate.svg"
          onClick={() => setShowLocatorPopup(true)}
        />
      </div>

      <div className="Popup">
        {
          showLocatorPopup &&
          <div className="modal">
            <div className="modal-content">
              <div className="Modal-Title">
                <p className="Text">Can't find your certificate?</p>
              </div>

              <div className="Modal-Body">
                {
                  isB2BBusiness(currentUser, currentWorkplace) ? (
                    <p className="Description">
                      Your certificates are located
                      <Link to="/account/certificate" className="Profile-Link">here</Link>
                      .
                    </p>
                  ) : (
                    <p className="Description">
                      Your certificates are located within your
                      <Link to="/account/profile/?tab=my-progress&sec=subscriptions" className="Profile-Link">Profile</Link>
                      under the 'My Progress' tab.
                    </p>
                  )
                }
                <p className="Description">
                  You'll need to complete all slides and content, and receive a score of 70% or higher on all quizzes and the final exam in order to receive your completion certificate for that level.
                </p>
              </div>

              <div className="Modal-Footer">
                <ButtonBox
                  className="Secondary-Btn Check-Button"
                  text={<span className="Secondary-Btn-Text">OK, I'll double-check</span>}
                  onClick={() => setShowLocatorPopup(false)}
                />

                <a href="https://share.hsforms.com/1559vlmxjRfCWYGzpkgwP_A3jlta?question_type=Missing%20Certificate" target="_blank" rel="noopener noreferrer">
                  <ButtonBox
                    className="Static-Primary-Btn Contact-Button"
                    text="Contact support"
                    src="newTabArrow.svg"
                    onClick={() => setShowLocatorPopup(false)}
                  />
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateLocator);
